import React from 'react'
const ProfileImage = ({
    C1,
    C2,
    ImageSource,
    ImageClass,
    Alt
}) => {
    return (
        <section className={C1}>
            <div className={C2}>
                <img
                    src={ImageSource}
                    className={ImageClass}
                    alt={Alt}
                />
            </div>
        </section>
    )
}

export default ProfileImage
