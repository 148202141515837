import React from 'react';

export const PersonalPurchase = React.memo(() => (
    <svg
        fill="#000000"
        width="40"
        height="40"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        style={{ enableBackground: 'new 0 0 64 64' }}
    >
        <g>
            <path d="M37.1,48.2c-4,0-7.2,3.3-7.2,7.3s3.2,7.3,7.2,7.3c4,0,7.2-3.3,7.2-7.3S41.1,48.2,37.1,48.2z M37.1,59.3 c-2.1,0-3.7-1.7-3.7-3.8s1.7-3.8,3.7-3.8c2.1,0,3.7,1.7,3.7,3.8S39.2,59.3,37.1,59.3z" />
            <path d="M17.9,48.2c-4,0-7.2,3.3-7.2,7.3s3.2,7.3,7.2,7.3s7.2-3.3,7.2-7.3S21.9,48.2,17.9,48.2z M17.9,59.3c-2.1,0-3.7-1.7-3.7-3.8 s1.7-3.8,3.7-3.8c2.1,0,3.7,1.7,3.7,3.8S20,59.3,17.9,59.3z" />
            <path d="M58.4,1.3H53c-2.2,0-4.1,1.6-4.4,3.8l-1.7,12.1h-5.2V9.2c0-1.5-1.2-2.7-2.7-2.7h-7.9C31,5,29.9,3.9,28.5,3.9h-8.7 c-1.5,0-2.7,1.3-2.7,2.9v2.4H9.2c-1.5,0-2.7,1.1-2.7,2.6v5.4c-0.8,0.1-1.6,0.4-2.1,1.1c-0.5,0.7-0.7,1.6-0.5,2.4c0,0,0,0.1,0,0.1 l6.2,18.9c0.3,1.2,1.5,2.1,2.8,2.1h27.9c3.5,0,6.5-2.6,7-6.1l4.3-30.1c0.1-0.4,0.4-0.8,0.9-0.8h5.4c1,0,1.8-0.8,1.8-1.8 S59.4,1.3,58.4,1.3z M38.2,17.1h-7.1V10h7.1V17.1z M20.6,7.4h7.1v1.8v7.9h-7.1v-5.3V7.4z M10,12.7h7.1v4.4H10V12.7z M44.3,35.2 c-0.2,1.7-1.8,3.1-3.5,3.1H13.4L7.6,20.6h9.5h3.5h7.1h3.5h10.6h4.7L44.3,35.2z" />
        </g>
    </svg>
));

export const UsersIcon = React.memo(() => (
    <svg
        fill="#000000"
        width="40"
        height="40"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        style={{ enableBackground: 'new 0 0 64 64' }}
    >
        <g>
            <path d="M21.5,36.4c6.8,0,12.3-5.5,12.3-12.3s-5.5-12.3-12.3-12.3S9.2,17.3,9.2,24.1S14.7,36.4,21.5,36.4z M21.5,15.3
          c4.8,0,8.8,3.9,8.8,8.8c0,4.9-3.9,8.8-8.8,8.8s-8.8-3.9-8.8-8.8C12.7,19.2,16.6,15.3,21.5,15.3z"/>
            <path d="M21.5,40.8c-7.3,0-14.3,3-19.7,8.4c-0.7,0.7-0.7,1.8,0,2.5C2.1,52,2.6,52.2,3,52.2c0.5,0,0.9-0.2,1.2-0.5
          c4.7-4.8,10.8-7.4,17.2-7.4c6.3,0,12.4,2.6,17.2,7.4c0.7,0.7,1.8,0.7,2.5,0c0.7-0.7,0.7-1.8,0-2.5C35.7,43.8,28.7,40.8,21.5,40.8z"/>
            <path d="M47.8,36.4c3.9,0,7-3.2,7-7s-3.2-7-7-7s-7,3.2-7,7S43.9,36.4,47.8,36.4z M47.8,25.8c1.9,0,3.5,1.6,3.5,3.5
          s-1.6,3.5-3.5,3.5s-3.5-1.6-3.5-3.5S45.9,25.8,47.8,25.8z"/>
            <path d="M62.2,46.5c-5.3-5-12.7-6.9-20.1-5c-0.9,0.2-1.5,1.2-1.3,2.1c0.2,0.9,1.2,1.5,2.1,1.3c6.2-1.6,12.4,0,16.8,4.2
          c0.3,0.3,0.8,0.5,1.2,0.5c0.5,0,0.9-0.2,1.3-0.6C62.9,48.3,62.9,47.2,62.2,46.5z"/>
        </g>
    </svg>
));

export const CertificateIcon = React.memo(() => (
    <svg
        fill="#000000"
        width="40"
        height="40"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        style={{ enableBackground: 'new 0 0 64 64' }}
    >
        <path d="M57,35.5h-4.8V8.8c0-4-3.1-7.3-7-7.5l-32.7,0C6.3,1.2,1.3,6.5,1.3,12.9v4.4c0,1.8,1.5,3.3,3.3,3.3h7.3v28.2
        c0,3.3,2.8,6,6.2,6h4.4v5.8c0,0.8,0.4,1.5,1.1,1.9c0.3,0.2,0.7,0.3,1,0.3c0.4,0,0.8-0.1,1.2-0.3l5.1-3.3l5,3.2l0.2,0.1
        c0.7,0.3,1.5,0.3,2.2-0.1c0.6-0.4,1-1.1,1-1.8v-5.8h9.7h7H57c3.2,0,5.8-2.6,5.8-5.8v-7.8C62.8,38.1,60.2,35.5,57,35.5z M4.8,12.9
        c0-4.5,3.5-8.2,7.7-8.2H37c-0.9,1.4-1.5,3.1-1.5,4.9v7.5H4.8V12.9z M30.7,45.5c-4.1,0-7.4-3.3-7.4-7.3c0-4,3.3-7.3,7.4-7.3
        c4.1,0,7.4,3.3,7.4,7.3C38.1,42.2,34.8,45.5,30.7,45.5z M32.1,55.9c-0.9-0.6-2-0.6-2.9,0l-3.4,2.2V47.9c1.5,0.7,3.1,1.1,4.8,1.1
        c1.7,0,3.4-0.4,4.9-1.1v10.2L32.1,55.9z M39,51.3v-6.2c1.6-1.9,2.6-4.3,2.6-7c0-6-4.9-10.8-10.9-10.8c-6,0-10.9,4.9-10.9,10.8
        c0,2.7,1,5.1,2.6,7v6.1H18c-1.5,0-2.7-1.1-2.7-2.5V20.6h20.5c1.8,0,3.3-1.5,3.3-3.3V9.6c0-2.5,1.8-4.7,4.2-4.9h1.6
        c2.1,0,3.9,1.8,3.9,4v26.7v12.3v3.5H39z M59.3,49.1c0,1.2-1,2.3-2.3,2.3h-1.3h-3.5v-3.5V39H57c1.2,0,2.3,1,2.3,2.3V49.1z"/>
    </svg>
));

export const EnvelopeCheckIcon = React.memo(() => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="currentColor" // Default fill, you can customize it by passing props
        viewBox="0 0 16 16"
    >
        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
    </svg>
));

export const RevenueIcon = React.memo(() => (
    <svg
        fill="#000000" // Default fill, you can customize it by passing props
        width="40"
        height="40"
        version="1.1"
        id="lni_lni-revenue"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        style={{ enableBackground: "new 0 0 64 64" }}
        xmlSpace="preserve"
    >
        <g>
            <path d="M62.5,45.4c-1-3.2-4.4-5-7.7-4.2l-13.2,3.1v-0.2c0-3-2.3-5.5-5.5-6l-13.5,0c-0.8-1.6-2.5-2.6-4.4-2.6h-12c-2.7,0-5,2.2-5,5
        v14.6c0,2.8,2.2,5,5,5h11.8c1.4,0,2.8-0.6,3.8-1.6c0.3-0.3,0.5-0.6,0.7-0.9l0.4,0.2c2.9,1.5,6.1,2.2,9.3,2.2c1.9,0,3.8-0.3,5.7-0.8
        l15.7-4.4l0,0c0,0,0,0,0,0l4.5-1.3c1.7-0.4,3-1.5,3.8-3C62.8,48.9,63,47.1,62.5,45.4z M19.3,56.1c-0.3,0.3-0.8,0.5-1.2,0.5H6.3
        c-0.8,0-1.5-0.7-1.5-1.5V40.5c0-0.8,0.7-1.5,1.5-1.5h12c0.8,0,1.5,0.7,1.5,1.5l0,14.5C19.7,55.5,19.5,55.9,19.3,56.1z M58.9,48.7
        c-0.4,0.7-1,1.1-1.7,1.3l-4.5,1.3l0,0l-15.7,4.4c-4.2,1.2-8.6,0.8-12.4-1.2L23.2,54V41.7h12.5c1.4,0.3,2.4,1.3,2.4,2.5v1l-3.9,0.9
        c-0.9,0.2-1.5,1.2-1.3,2.1c0.2,0.9,1.2,1.5,2.1,1.3l20.6-4.9c1.5-0.4,3,0.4,3.5,1.9C59.4,47.2,59.3,48,58.9,48.7z"></path>
            <path d="M47.8,20.6h5.3c1.9,0,3.5,1.6,3.5,3.5s-1.6,3.5-3.5,3.5h-7.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h3.5V32
        c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-0.9h0.9c3.8,0,7-3.2,7-7s-3.1-7-7-7h-5.3c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5h7.9
        c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8h-3.5V5.6c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8v0.9h-0.9c-3.8,0-7,3.2-7,7
        S44,20.6,47.8,20.6z"></path>
        </g>
    </svg>
));

export const CustomIcon = React.memo(() => (
    <svg
        viewBox="0 0 1024 1024"
        width={40}
        className="icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
    >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path
                d="M861.9 383.8H218.1c-36.4 0-66.1-29.8-66.1-66.1V288c0-36.4 29.8-66.1 66.1-66.1h643.8c36.4 0 66.1 29.8 66.1 66.1v29.7c0 36.3-29.8 66.1-66.1 66.1z"
                fill="#FFB89A"
            ></path>
            <path
                d="M822.9 129.2H199.8c-77.2 0-140.4 63.2-140.4 140.4v487.2c0 77.2 63.2 140.4 140.4 140.4h623.1c77.2 0 140.4-63.2 140.4-140.4V269.6c0-77.2-63.2-140.4-140.4-140.4z m80.4 177H760.4L864.6 201c5.4 3.3 10.4 7.3 15 11.8 15.3 15.3 23.7 35.4 23.7 56.8v36.6z m-673.3 0l104-117h61.3l-109.1 117H230z m247.4-117h169.2L532 306.2H368.3l109.1-117z m248.8 0h65.6L676 306.2h-60l112.5-114.8-2.3-2.2zM143 212.9c15.3-15.3 35.4-23.7 56.8-23.7h53.9l-104 117h-30.4v-36.5c0.1-21.4 8.5-41.5 23.7-56.8z m736.6 600.7c-15.3 15.3-35.4 23.7-56.8 23.7h-623c-21.3 0-41.5-8.4-56.8-23.7-15.3-15.3-23.7-35.4-23.7-56.8V366.2h783.9v390.6c0.1 21.3-8.3 41.5-23.6 56.8z"
                fill="#45484C"
            ></path>
            <path
                d="M400.5 770.6V430.9L534.1 508c14.3 8.3 19.3 26.6 11 41-8.3 14.3-26.6 19.3-41 11l-43.6-25.2v131.8l114.1-65.9-7.5-4.3c-14.3-8.3-19.3-26.6-11-41 8.3-14.3 26.6-19.3 41-11l97.5 56.3-294.1 169.9z"
                fill="#45484C"
            ></path>
        </g>
    </svg>
));


export const ShopIcon = React.memo(() => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="currentColor"
        className="bi bi-shop"
        viewBox="0 0 16 16"
    >
        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"></path>
    </svg>
));

export const CoinIcon = React.memo(() => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="currentColor"
        className="bi bi-coin"
        viewBox="0 0 16 16"
    >
        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z"></path>
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12"></path>
    </svg>
));

export const BarChartFillIcon = React.memo(() => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="currentColor"
        className="bi bi-bar-chart-fill"
        viewBox="0 0 16 16"
    >
        <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"></path>
    </svg>
));






