import React from 'react';
import Return from '../../assets/icons/Return2.png';
import Logo from '../../assets/icons/logo.svg';
import Serial from '../../assets/icons/serial.png'
const ViewComponents = (props) => {
    const { BackButton } = props
    return (
        <div className="w-full relative min-h-screen overflow-scroll -scrollbar-hidden">
            <div className="fixed bottom-1 left-1 lg:left-0 lg:top-0 lg:absolute lg:-translate-x-[100px] lg:-translate-y-[100px] z-20">
                <div onClick={() => BackButton(true)} className="w-[50px] h-[50px] lg:w-[200px] lg:h-[200px] flex justify-center items-center rounded-full cursor-pointer bg-[#4963b8]">
                    <img src={Return} className="rounded-full lg:w-[40px] lg:translate-x-[30px] lg:translate-y-[40px]" alt="Return" />
                </div>
            </div>
            <div className="min-w-[800px] lg:w-full flex justify-center absolute top-[60px] lg:top-16 left-0 overflow-hidden lg:p-4">
                <div className='w-full lg:w-[90%] border-[1px] border-[#292121] lg:rounded-xl'>
                    <table className="w-full border-collapse text-sm text-gray-800">
                        <tbody>
                            <tr className="border-b border-[#3b2e2e]">
                                <td className="text-center border-r-[1px] border-r-[#292121] relative font-bold py-5" colSpan="6">
                                    <img src={Logo} alt="Logo" className="inline-block w-[60px] h-[60px] mr-2" />
                                    <span id="invoice_title_company" className='absolute top-3 left-5'>sibmlm</span>
                                </td>
                                <td className="text-center text-[20px] font-bold" colSpan="6">
                                    حواله تحویل کالا
                                </td>
                            </tr>
                            <tr className="border-b border-[#3b2e2e]">
                                <td className="text-center relative py-2 border-r-[1px] border-[#292121]" colSpan="4">
                                    <div className='w-full'>
                                        <span className='text-sm font-semibold'>شماره فاکتور :</span>
                                        <span className='mx-1 persian-number text-sm font-semibold'>39859</span>
                                    </div>
                                    <div className='w-full flex justify-center'>
                                        <img src={Serial} />
                                    </div>
                                </td>
                                <td colSpan="6" className='text-[15px] font-semibold text-center'>نام و نام خانوادگی : محمدرضا بهرامی راد</td>
                                <td colSpan="3" className='border-l-[1px] border-l-[#3b2e2e] text-center font-semibold text-[13px]'>21:42:42 30-07-1403</td>
                            </tr>
                            <tr className="border-b border-[#3b2e2e]">
                                <td className='py-3 text-center' colSpan="3">
                                    <span className='font-semibold text-[13px]'>موبایل : </span>
                                    <span className='font-semibold text-[13px]'>989122770733</span>
                                </td>
                                <td className='py-3 border-l-[1px] text-center border-[#3b2e2e]' colSpan="3">
                                    <span className='text-[13px] font-semibold'>کد پستی :</span>
                                    <span className='text-[13px] font-semibold persian-number mx-2'>1998135891</span>
                                </td>
                                <td className='py-3 text-center border-l-[1px] border-[#3b2e2e]' colSpan="3">
                                    <span className='text-[13px] font-semibold'>وضعیت سفارش :</span>
                                    <span className='text-[13px] font-semibold mx-2'>در حال پردازش</span>
                                </td>
                                <td className='py-3 border-l-[1px] border-[#3b2e2e] text-center' colSpan="2">
                                    <span className='text-[13px] font-semibold'>کد ملی :</span>
                                    <span className='mx-3 persian-number text-[13px] font-semibold'>1830567345</span>
                                </td>
                            </tr>
                            <tr className="border-b border-[#3b2e2e]">
                                <td colSpan="12" className="text-right py-5 text-[15px] font-semibold">
                                    <span className='mx-5'>آدرس کامل : تهران - تهران - تهران سعادت آباد کوچه شهید مجید نامی کوچه آریا پلاک یک واحد دو</span>
                                </td>
                            </tr>
                            <tr className="text-center font-bold border-b border-[#3b2e2e] bg-gray-100">
                                <th className='py-5 px-10' colSpan="2">مبلغ کل</th>
                                <th className='py-5 border-l-[1px] border-[#3b2e2e]'>مبلغ</th>
                                <th className='py-5 border-l-[1px] border-[#3b2e2e]'>مانده</th>
                                <th className='py-5 border-l-[1px] border-[#3b2e2e]' colSpan="2">روش تحویل</th>
                                <th className='py-5 border-l-[1px] border-[#3b2e2e]'>تعداد کل</th>
                                <th className='py-5 border-l-[1px] border-[#3b2e2e]' colSpan="2">عنوان کالا</th>
                                <th className='py-5 border-l-[1px] border-[#3b2e2e]' colSpan="1">کد کالا</th>
                                <th className='py-5 border-l-[1px] border-[#3b2e2e]'>ردیف</th>
                            </tr>
                            <tr className="text-center border-b border-[#3b2e2e]">
                                <td colSpan="2" className="my-1 persian-number">1,500,000</td>
                                <td className="persian-number border-l-[1px] border-l-[#3b2e2e]">1,500,000</td>
                                <td className='border-l-[1px] border-l-[#3b2e2e] persian-number'>1</td>
                                <td colSpan="2" className='border-l-[1px] border-l-[#3b2e2e]'>
                                    <div className='w-full'>
                                        <div className='w-full h-full grid grid-cols-2'>
                                            <div className='w-full h-full grid grid-cols-3'>
                                                <div className='border-b-[1px] border-[#3b2e2e] flex justify-center items-center'>م</div>
                                                <div className='border-l-[1px] border-b-[1px] border-[#3b2e2e] flex justify-center items-center'>ت</div>
                                                <div className='border-l-[1px] border-b-[1px] border-[#3b2e2e] flex justify-center items-center'>خ</div>
                                                <div className='border-[#3b2e2e] flex justify-center items-center'>۱</div>
                                                <div className='border-l-[1px] border-[#3b2e2e] flex justify-center items-center'>۰</div>
                                                <div className='border-l-[1px] border-[#3b2e2e] flex justify-center items-center'>۱</div>
                                            </div>
                                            <div className='w-full border-l-[1px] border-[#3b2e2e] flex flex-col h-full'>
                                                <div className='flex-1 border-b-[1px] border-[#3b2e2e] py-2'>انبار</div>
                                                <div className='flex-1 py-2'>ارسال پستی/انبار مرکزی</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className='border-l-[1px] border-l-[#3b2e2e]'>1</td>
                                <td colSpan="2" className='border-l-[1px] border-l-[#3b2e2e]'>اسپری ضدبوی کفش</td>
                                <td colSpan="1" className='persian-number border-l-[1px] border-l-[#3b2e2e]' >91601100</td>
                                <td className='persian-number border-l-[1px] border-l-[#3b2e2e]'>1</td>
                            </tr>
                            <tr className="border-b border-[#3b2e2e]">
                                <td colSpan="3" className="text-end px-5 persian-number py-5">1</td>
                                <td colSpan="4" className="text-[13px] border-l-[1px] font-semibold border-[#3b2e2e] text-end px-5">جمع کل تعداد</td>
                                <td style={{ direction: 'rtl' }} colSpan="3" className="pl-3 text-center border-l-[1px] border-[#3b2e2e]">
                                    <span className='persian-number text-[15px]'>1,920,864</span>
                                    <span className='text-[13px]'>ریال</span>
                                </td>
                                <td colSpan="1" className="text-[13px] font-semibold border-l-[1px] border-[#3b2e2e] text-center">جمع کل نهایی</td>
                            </tr>
                            <tr className="border-b border-[#3b2e2e]">
                                <td style={{ direction: 'rtl' }} colSpan="3" className="px-2 py-5">
                                    <span className='mx-1 persian-number text-[15px]'>600,000</span>
                                    <span className='persian-number text-[15px]'>ریال</span>
                                </td>
                                <td colSpan="4" className="text-[14px] font-semibold border-l-[1px] border-[#3b2e2e] text-end px-5">{`هزینه ارسال کالا (ریال)`}</td>
                                <td style={{ direction: 'rtl' }} colSpan="3" className="pl-3 border-l-[1px] border-[#3b2e2e] text-center">
                                    <span className='mx-3 persian-number'>0</span>
                                    <span>گرم</span>
                                </td>
                                <td colSpan="1" className="text-[14px] font-semibold text-center border-l-[1px] border-l-[#3b2e2e]">وزن کل</td>
                            </tr>
                            <tr className="border-b border-[#3b2e2e]">
                                <td style={{ direction: 'rtl' }} colSpan="3" className="text px-5 pl-3 py-5">
                                    <span className='mx-1 persian-number'>5,760</span>
                                    <span>ریال</span>
                                </td>
                                <td colSpan="4" className="text-[13px] text-end font-semibold px-5 border-l-[1px] border-[#3b2e2e]">تخفیف خرده فروشی</td>
                                <td colSpan="3" className="pl-3 border-l-[1px] border-[#3b2e2e] text-center" style={{ direction: 'rtl' }}>
                                    <span className='persian-number mx-1 text-[13px]'>174,624</span>
                                    <span className='persian-number'>ریال</span>
                                </td>
                                <td colSpan="1" className="text-[13px] font-semibold text-center border-l-[1px] border-l-[#3b2e2e]">مالیات برارزش افزوده (ریال)</td>
                            </tr>
                            <tr className="border-b border-[#3b2e2e]">
                                <td colSpan="10" className='text-end px-5 py-5'>
                                    اینجانب محمدرضا بهرامی راد در تاریخ 30-07-1403 با علم و اطلاع کامل از کلیه مقررات و قوانین بازاریابی شبکه ای و قوانین تجارت الکترونیک اعلام میدارم کلیه
                                    کالاهای خریداری شده از شرکت سفیران یگانه را مصرف نموده و یا به فروش رسانده ام و تایید این متن به منزله این است که
                                    هیج مانده موجودی کالایی تا این تاریخ ندارم
                                    <hr className="my-2" />
                                    کلیه محصولات به صورت سالم تحویل اینجانب ...........درمورخ ..................گردید.
                                </td>
                                <td className='border-l-[1px] border-[#3b2e2e] text-center text-[15px] font-semibold' colSpan="2">: روش تحویلی</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="text-center py-5">امضا دریافت کننده</td>
                                <td colSpan="8" className="text-end px-5 persian-number">
                                    آدرس: تهران سعادت آباد کوچه شهید مجید نامی _ کوچه آریا _ پلاک یک واحد دو تلفن 02122147602 کد پستی 1998135891
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ViewComponents;
