import React from 'react'

const SibYar = (props) => {
    const { width, Loading } = props
    const LoadingActivatorFunction = () => {
        Loading(true)
        setTimeout(() => {
            Loading(false)
        }, 500)
    }
    return (
        <div className='w-[calc(100vw-350px)] min-h-screen relative'>
            <div className='w-full px-[120px]'>
                <div className='w-full h-full flex justify-center mt-10'>
                    <div className='w-full grid grid-cols-4 gap-4'>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SibYar
