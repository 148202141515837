import React from 'react';
import { Collections } from '../../../data/CollectionComponent';
import Return2 from '../../../assets/icons/Return2.png';
const CollectionComponent = (props) => {
    const { BackButton } = props
    // Sort collections by date (descending order)
    const sortedCollections = Collections.sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
        <div className='w-full relative mt-[59px] lg:mt-0 xl:w-[calc(100vw-350px)] min-h-screen flex justify-center'>
            <div className='absolute top-0 left-0 flex justify-center cursor-pointer items-center w-[200px] h-[200px] -translate-x-[100px] -translate-y-[100px] bg-[#4963b8] rounded-full'>
                <img onClick={() => BackButton(true)} src={Return2} className='translate-x-[30px] translate-y-[40px] w-[40px]' />
            </div>
            <div className='w-full border border-gray-300 overflow-hidden'>
                <div className='bg-blue-800 text-[10px] sm:text-[11px] xl:text-[15px] persian-number text-white py-3 text-end px-5'>
                    <span className='mx-1'>درخواست های تجمیع برای فاکتور شماره :</span>
                    <span className='mx-1'>۳۹۸۵۹</span>
                </div>
                <div className='bg-red-100 text-[10px] sm:text-[11px] xl:text-[15px] persian-number text-red-600 text-end py-3 px-5'>
                    امکان ساخت کد جدید وجود ندارد؛ تعداد آیتم های موجود به حد نصاب رسیده است
                </div>
                <table className='w-full text-center border-collapse'>
                    <thead className='bg-gray-200'>
                        <tr>
                            <th className='py-3 px-4 persian-number text-[10px] sm:text-[12px] xl:text-[15px] whitespace-nowrap'>تاریخ</th>
                            <th className='py-3 px-4 persian-number text-[10px] sm:text-[12px] xl:text-[15px] whitespace-nowrap'>وضعیت</th>
                            <th className='py-3 px-4 persian-number text-[10px] sm:text-[12px] xl:text-[15px] whitespace-nowrap'>تعداد</th>
                            <th className='py-3 px-4 persian-number text-[10px] sm:text-[12px] xl:text-[15px] whitespace-nowrap'>کد</th>
                            <th className='py-3 px-4 persian-number text-[10px] sm:text-[12px] xl:text-[15px] whitespace-nowrap'>انبار</th>
                            <th className='py-3 px-4 persian-number text-[10px] sm:text-[12px] xl:text-[15px] whitespace-nowrap'>کالا</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedCollections.map((item, index) => (
                            <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                <td className={`py-3 px-4 border persian-number text-[10px] sm:text-[10px] xl:text-[15px]`}>
                                    {item.date}
                                </td>
                                <td className='py-3 px-4 border persian-number text-[10px]  sm:text-[10px] xl:text-[15px]'>{item.fullname}</td>
                                <td className='py-3 px-4 border persian-number text-[10px]  sm:text-[10px] xl:text-[15px]'>{item.username}</td>
                                <td className='py-3 px-4 border persian-number text-[10px]  sm:text-[10px] xl:text-[15px]'>{item.status}</td>
                                <td className='py-3 px-4 border persian-number text-[10px]  sm:text-[10px] xl:text-[15px]'>{item.collectiveRemittance}</td>
                                <td className='py-3 px-4 border persian-number text-[10px]  sm:text-[10px] xl:text-[15px]'>{item.code}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CollectionComponent;
