import React, { useEffect, useRef } from "react";
import { employees } from "../../data/Schematic";
import OrganizationalChart from "./Partials/orgChart";


const TreeSchematicWithDetails = ({ width }) => {
    return (
        <div className="w-full h-[100vh] card overflow-y-auto lg:w-[calc(100vw-350px)] bg-[#ffffff]">
            <div className="w-full h-full flex justify-center">
                <div className="container mt-10 w-full h-full">
                    <OrganizationalChart
                        data={employees}
                        width={width}
                    />
                </div>
            </div>
        </div>
    );
};

export default TreeSchematicWithDetails;
