import React from 'react'
import Return from '../../../assets/icons/Return2.png';
import { GoodsInvoiceDelivery } from '../../../data/InvoiceDelivery'
const ResponsiveInvoiceDelivery = (props) => {
    const { BackButton } = props
    return (
        <div className='w-full min-h-screen relative'>
            <div className='w-full absolute bottom-0 h-[calc(100vh-60px)]'>
                <div className='w-full px-10 persian-number text-white h-[50px] flex justify-between items-center bg-[#5B7190]'>
                    <div className='text-[11px]'>
                        <span>فاکتور : </span>
                        <span>{GoodsInvoiceDelivery.Remittance}</span>
                    </div>
                    <div className='text-[11px] sm:text-[15px]'>کد ها تحویل حضوری کالا</div>
                </div>
                <div className='w-full bg-[#ce4f4f]'>
                    <div className='text-[10px] sm:text-[15px] w-full text-center text-white pt-5'>!!!اخطار</div>
                    <div className='text-[10px] sm:text-[15px] w-full text-center pt-3 pb-5 text-white px-5'>
                        <span>در اختیار قرار دادن کدهای تحویل به انبار دار به منزله تحویل کالا می باشد</span>
                    </div>
                </div>
                <div className='w-full mt-5 text-[12px] sm:text-[15px] px-3 sm:px-10 persian-number'>
                    <div className='w-full border-[1px] py-4 px-10 rounded-[50px] border-[rgba(0,0,0,0.1)] flex justify-between items-center'>
                        <div>{GoodsInvoiceDelivery.Goods}</div>
                        <div>کالا</div>
                    </div>
                    <div className='w-full mt-5 flex justify-between border-[1px] py-4 px-10 rounded-[50px] border-[rgba(0,0,0,0.1)] items-center'>
                        <div>{GoodsInvoiceDelivery.Storage}</div>
                        <div>انبار</div>
                    </div>
                    <div className='w-full mt-5 flex justify-between border-[1px] py-4 px-10 rounded-[50px] border-[rgba(0,0,0,0.1)]'>
                        <div>{GoodsInvoiceDelivery.Code}</div>
                        <div>کد</div>
                    </div>
                    <div className='w-full mt-5 flex justify-between border-[1px] py-4 px-10 rounded-[50px] border-[rgba(0,0,0,0.1)]'>
                        <div>{GoodsInvoiceDelivery.Count}</div>
                        <div>تعداد</div>
                    </div>
                    <div className='w-full mt-5 flex justify-between border-[1px] py-4 px-10 rounded-[50px] border-[rgba(0,0,0,0.1)]'>
                        <div>{GoodsInvoiceDelivery.date}</div>
                        <div>تاریخ</div>
                    </div>
                </div>
            </div>
            <div onClick={() => BackButton(true)} className='fixed bottom-1 left-1 w-[50px] h-[50px] p-2 rounded-full bg-[#3b729e]'>
                <img src={Return} />
            </div>
        </div>
    )
}

export default ResponsiveInvoiceDelivery
