import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader'; // Import the spinner
import ResponsiveComponent from './Partials/ResponsiveComponent';
import ResponsiveWithMobileComponent from './Partials/ResponsiveWithMobileComponent';
import { transactions } from '../../data/GoodsDeliveryRemittance';
import ViewComponents from '../ViewComponents/ViewComponents';
import ResponsiveRemittance from '../ResponsiveRemittance/ResponsiveRemittance';
import CollectionComponent from './Partials/CollectionComponent';
import ResponsiveCollection from './Partials/ResponsiveCollection';
import InvoiceDelivery from './Partials/InvoiceDelivery';
import ResponsiveInvoiceDelivery from './Partials/ResponsiveInvoiceDelivery';
const GoodsDeliveryRemittance = (props) => {
    const { width, Loading } = props;
    const [selectedComponents, setSelectedComponents] = useState();
    const [changeComponentStatus, setChangeComponentStatus] = useState(true);
    const [loading, setLoading] = useState(true); // State to control loading status
    const [componentMemo, setComponentMemo] = useState({
        BTN: ''
    });
    const BackButton = (data) => {
        setLoading(data)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
        setChangeComponentStatus(true)
    }
    const setLoadingStatus = (data) => {
        Loading(data);
        setLoading(data); // Set loading status here
    };

    const RenderButtomComponents = (data) => {
        setLoading(true); // Show spinner when the button is clicked
        setComponentMemo((prev) => ({ ...prev, BTN: data.BTN }));
        console.log(data.BTN)
        switch (data.BTN) {
            case 'ViewButtom':
                setChangeComponentStatus(false);
                setSelectedComponents(width >= 1280 ? <ViewComponents BackButton={BackButton} /> : <ResponsiveRemittance BackButton={BackButton} />);
                break;
            case 'CollectButtom':
                setChangeComponentStatus(false);
                setSelectedComponents(width >= 768 ? <CollectionComponent BackButton={BackButton} /> : <ResponsiveCollection BackButton={BackButton} />);
                break;
            case 'CodeDelivery':
                setChangeComponentStatus(false);
                setSelectedComponents(width >= 768 ? <InvoiceDelivery BackButton={BackButton} /> : <ResponsiveInvoiceDelivery BackButton={BackButton} />);
                break;
            default:
                setChangeComponentStatus(true);
                break;
        }

        // Simulate a loading delay (for example purposes)
        setTimeout(() => {
            setLoading(false); // Stop loading after a delay
        }, 1000); // Adjust the timeout as necessary
    };

    useEffect(() => {
        // Handle component rendering based on width and BTN using switch
        switch (componentMemo.BTN) {
            case 'ViewButtom':
                setSelectedComponents(width >= 1280 ? <ViewComponents BackButton={BackButton} /> : <ResponsiveRemittance BackButton={BackButton} />);
                break;
            case 'CollectButtom':
                setChangeComponentStatus(false);
                setSelectedComponents(width >= 768 ? <CollectionComponent BackButton={BackButton} /> : <ResponsiveCollection BackButton={BackButton} />);
                break;
            case 'CodeDelivery':
                setChangeComponentStatus(false);
                setSelectedComponents(width >= 768 ? <InvoiceDelivery BackButton={BackButton} /> : <ResponsiveInvoiceDelivery BackButton={BackButton} />);
                break;
            // You can add additional cases here if needed
            default:
                break;
        }
    }, [width, componentMemo.BTN]);

    useEffect(() => {
        // Simulate initial loading scenario
        const timeout = setTimeout(() => {
            setLoading(false); // Stop loading after a delay
        }, 1000); // Set 1 second loading time for example

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className='lg:w-[calc(100vw-348px)] xl:w-[calc(100vw-350px)] min-h-screen'>
            {loading ? (
                // Display the spinner while loading is true
                <div className='flex justify-center items-center h-screen'>
                    <ClipLoader color={'#123abc'} loading={loading} size={50} />
                </div>
            ) : (
                changeComponentStatus ? (
                    <>
                        <div className='w-full h-screen hidden sm:block'>
                            <ResponsiveComponent
                                transactions={transactions}
                                setLoadingStatus={setLoadingStatus}
                                RenderButtomComponents={RenderButtomComponents}
                            />
                        </div>

                        <div className='w-full h-screen block sm:hidden'>
                            <ResponsiveWithMobileComponent
                                transactions={transactions}
                                setLoadingStatus={setLoadingStatus}
                                RenderButtomComponents={RenderButtomComponents}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {selectedComponents}
                    </>
                )
            )}
        </div>
    );
};

export default GoodsDeliveryRemittance;
