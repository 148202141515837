import React from 'react'
import { GoodsInvoiceDelivery } from '../../../data/InvoiceDelivery';
import Return2 from '../../../assets/icons/Return2.png';
const InvoiceDelivery = (props) => {
    const { BackButton } = props
    return (
        <div className='2xl:w-[calc(100vw-350px)] persian-number min-h-screen relative'>
            <div className='absolute top-[50px] lg:top-0 flex justify-center cursor-pointer items-center w-[200px] h-[200px] z-20 -translate-x-[100px] -translate-y-[100px] bg-[#4963b8] rounded-full'>
                <img onClick={() => BackButton(true)} src={Return2} className='translate-x-[30px] translate-y-[40px] w-[40px]' />
            </div>
            <div className='w-full absolute top-0 left-0'>
                <div className='w-full min-h-screen'>
                    <div className='w-full h-[50px] flex items-center justify-end px-10 text-white bg-[#5B7190]'>
                        <span className='persian-number'>{GoodsInvoiceDelivery.Remittance}</span>
                        <span className=''> <span className='mx-3'>:</span> فاکتور</span>
                        <span className=''>-کدهای تحویل حضوری کالا</span>
                    </div>
                    <div className='w-full flex justify-end bg-[#ce4f4f]'>
                        <div className='h-full text-white text-end'>
                            <div className='px-10 pt-5'>!!! اخطار</div>
                            <div className='px-10 py-5'>در اختیار قرار دادن کدهای تحویل به انبار دار به منزله تحویل کالا می باشد</div>
                        </div>
                    </div>
                    <div className='w-full'>
                    <table className='w-full text-right border-collapse'>
                            <thead>
                                <tr className='bg-[#5B7190] text-white'>
                                    <th className='py-2 px-4 border text-[13px] lg:text-[15px]'>تاریخ</th>
                                    <th className='py-2 px-4 border text-[13px] lg:text-[15px]'>وضعیت</th>
                                    <th className='py-2 px-4 border text-[13px] lg:text-[15px]'>تعداد</th>
                                    <th className='py-2 px-4 border text-[13px] lg:text-[15px]'>کد</th>
                                    <th className='py-2 px-4 border text-[13px] lg:text-[15px]'>انبار</th>
                                    <th className='py-2 px-4 border text-[13px] lg:text-[15px]'>کالا</th>
                                    <th className='py-2 px-4 border text-[13px] lg:text-[15px]'>#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='bg-gray-100'>
                                    <td className='py-2 px-4 border text-[13px] lg:text-[15px]'>{GoodsInvoiceDelivery.date}</td>
                                    <td className='py-2 px-4 border text-[13px] lg:text-[15px]'>{GoodsInvoiceDelivery.Status}</td>
                                    <td className='py-2 px-4 border text-[13px] lg:text-[15px]'>{GoodsInvoiceDelivery.Count}</td>
                                    <td className='py-2 px-4 border text-[13px] lg:text-[15px]'>{GoodsInvoiceDelivery.Code}</td>
                                    <td className='py-2 px-4 border text-[13px] lg:text-[15px]'>{GoodsInvoiceDelivery.Storage}</td>
                                    <td className='py-2 px-4 border text-[13px] lg:text-[15px]'>{GoodsInvoiceDelivery.Goods}</td>
                                    <td className='py-2 px-4 border text-[13px] lg:text-[15px]'>1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceDelivery
