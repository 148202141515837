import React from 'react'
import AddAddress from '../../../assets/icons/AddAddress.gif';
const LocationIcon = (props) => {
    const {
        C1,
        C2,
        getAddress
    } = props
    return (
        <div onClick={() => getAddress(true)} className={C1}>
            <img src={AddAddress} className={C2} />
        </div>
    )
}

export default LocationIcon
