import React, { useEffect, useState } from 'react'
import ProfileImage from '../../assets/icons/profileImage.png'
import TeamList from './Partials/TeamList'
const TeamActivitist = (props) => {
    const { width, Loading } = props
    const [TeamPeople, setTeampeople] = useState([
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
    ])
    // useEffect(()=>{Loading(true)})
    return (
        <>
            <TeamList List={TeamPeople} />
        </>
    )
}

export default TeamActivitist
