import {
    NewStudent,
    FeatureMakerOneStar,
    ConsultantOneStar,
    SelsManager,
    OneStartGuid,
    AnStartStrategy,
    ExamsResults,
} from "./Icon"

export const VistaWidgets = [
    {
        position: 'نواموز',
        img: <NewStudent />,
    },
    {
        position: 'اینده ساز چند ستاره',
        img: <FeatureMakerOneStar />,
    },
    {
        position: 'مشاور یک ستاره',
        img: <ConsultantOneStar />
    },
    {
        position: 'مدیر فروش ارشد یک ستاره',
        img: <SelsManager />
    },
    {
        position: 'راهنمای یک ستاره',
        img: <OneStartGuid />,
    },
    {
        position: 'راهبر یک ستاره',
        img: <AnStartStrategy />,
    },
    {
        position: 'نتایج ازمون ها',
        img: <ExamsResults />
    }
]