import React, { useEffect, useRef } from 'react'

const SubMenu = ({
    SubMenuList,
    C1,
    C2,
    C3,
    C4,
    C5,
    onOutsideClick
}) => {
    const ref = useRef(null);

    useEffect(() => {
        // Function to handle outside click
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();  // Trigger the action when click is outside the element
            }
        };

        // Attach the event listener to the document
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onOutsideClick]);

    return (
        <div ref={ref} className={C1}>
            <ul className={C2}>
                {
                    SubMenuList.map((item, index) => (
                        <li className={C3} key={index}>
                            <span className={C4}>{item.name}</span>
                            <span className={C5}>{item.icon}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default SubMenu
