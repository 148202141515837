import React,{useState,useEffect} from 'react'
import ProfileImage from '../../assets/icons/profileImage.png'
import TeamList from '../TeamActivitist/Partials/TeamList'
const PeopleWhoDontHavePurchase = (props) => {
    const { width, Loading } = props
    const [TeamPeople, setTeampeople] = useState([
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
        {
            profileImage: ProfileImage,
            firstname: 'میثم',
            lastname: 'نیک اختر',
            username: 'vt99339947310',
            totalPurchase: 1458000,
            unit: 'ریال',
            level: 11

        },
    ])
    // useEffect(()=>{Loading(true)})
    return (
       <>
       <TeamList List={TeamPeople}/>
       </>
    )
}

export default PeopleWhoDontHavePurchase
