import React from 'react';

export const DashboardIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-speedometer" viewBox="0 0 16 16">
        <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z"></path>
        <path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z"></path>
    </svg>
);

// Add other icon components similarly, e.g.:
export const SalesNetworkIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-diagram-3-fill me-2" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5z"></path>
    </svg>
);


export const CommissionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"></path>
    </svg>
);

export const MyShopIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-shop" viewBox="0 0 16 16">
        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"></path>
    </svg>
);

export const TeamMembersIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person-check-fill" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
    </svg>
);

export const DeliveryIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box2-heart-fill" viewBox="0 0 16 16">
        <path d="M3.75 0a1 1 0 0 0-.8.4L.1 4.2a.5.5 0 0 0-.1.3V15a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4.5a.5.5 0 0 0-.1-.3L13.05.4a1 1 0 0 0-.8-.4zM8.5 4h6l.5.667V5H1v-.333L1.5 4h6V1h1zM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"></path>
    </svg>
);

export const SupportIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-joystick" viewBox="0 0 16 16">
        <path d="M10 2a2 2 0 0 1-1.5 1.937v5.087c.863.083 1.5.377 1.5.726 0 .414-.895.75-2 .75s-2-.336-2-.75c0-.35.637-.643 1.5-.726V3.937A2 2 0 1 1 10 2z"></path>
        <path d="M0 9.665v1.717a1 1 0 0 0 .553.894l6.553 3.277a2 2 0 0 0 1.788 0l6.553-3.277a1 1 0 0 0 .553-.894V9.665c0-.1-.06-.19-.152-.23L9.5 6.715v.993l5.227 2.178a.125.125 0 0 1 .001.23l-5.94 2.546a2 2 0 0 1-1.576 0l-5.94-2.546a.125.125 0 0 1 .001-.23L6.5 7.708l-.013-.988L.152 9.435a.25.25 0 0 0-.152.23z"></path>
    </svg>
);

export const ProfileIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
    </svg>
);

export const LogoutIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"></path>
        <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"></path>
    </svg>
);

export const Linear = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-collection" viewBox="0 0 16 16">
        <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z"></path>
    </svg>
)

export const ProductTransfer = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrows-angle-contract" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z"></path>
    </svg>
)

export const ProductTransfer2 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-geo-fill" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"></path>
    </svg>
)

export const TreeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#789395" className="bi bi-diagram-3-fill" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"></path>
    </svg>
)

export const Level = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#789395" className="bi bi-border-width" viewBox="0 0 16 16">
        <path d="M0 3.5A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-2zm0 5A.5.5 0 0 1 .5 8h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1zm0 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z"></path>
    </svg>
)

export const Vista = () => (
    <svg viewBox="0 0 1024 1024" width="20" height="20" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            {/* <path d="M861.9 383.8H218.1c-36.4 0-66.1-29.8-66.1-66.1V288c0-36.4 29.8-66.1 66.1-66.1h643.8c36.4 0 66.1 29.8 66.1 66.1v29.7c0 36.3-29.8 66.1-66.1 66.1z" fill="#ffffff"></path> */}
            <path d="M822.9 129.2H199.8c-77.2 0-140.4 63.2-140.4 140.4v487.2c0 77.2 63.2 140.4 140.4 140.4h623.1c77.2 0 140.4-63.2 140.4-140.4V269.6c0-77.2-63.2-140.4-140.4-140.4z m80.4 177H760.4L864.6 201c5.4 3.3 10.4 7.3 15 11.8 15.3 15.3 23.7 35.4 23.7 56.8v36.6z m-673.3 0l104-117h61.3l-109.1 117H230z m247.4-117h169.2L532 306.2H368.3l109.1-117z m248.8 0h65.6L676 306.2h-60l112.5-114.8-2.3-2.2zM143 212.9c15.3-15.3 35.4-23.7 56.8-23.7h53.9l-104 117h-30.4v-36.5c0.1-21.4 8.5-41.5 23.7-56.8z m736.6 600.7c-15.3 15.3-35.4 23.7-56.8 23.7h-623c-21.3 0-41.5-8.4-56.8-23.7-15.3-15.3-23.7-35.4-23.7-56.8V366.2h783.9v390.6c0.1 21.3-8.3 41.5-23.6 56.8z" fill="#ffffff"></path>
            <path d="M400.5 770.6V430.9L534.1 508c14.3 8.3 19.3 26.6 11 41-8.3 14.3-26.6 19.3-41 11l-43.6-25.2v131.8l114.1-65.9-7.5-4.3c-14.3-8.3-19.3-26.6-11-41 8.3-14.3 26.6-19.3 41-11l97.5 56.3-294.1 169.9z" fill="#ffffff"></path>
        </g>
    </svg>
)

export const Analysis = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-bar-chart-fill" viewBox="0 0 16 16">
        <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"></path>
    </svg>
)

export const NewStudent = () => (
    <svg height="50" width="50" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.663 392.663" xmlSpace="preserve">
        <defs>
            <style>
                {`
                    .fill-yellow { fill: #FFC10D; }
                    .fill-blue { fill: #56ACE0; }
                    .fill-darkblue { fill: #194F82; }
                    .fill-white { fill: #FFFFFF; }
                `}
            </style>
        </defs>
        <g>
            <path className="fill-yellow" d="M269.77,313.988h79.192v-23.79l-64-80.549C283.41,246.174,278.238,282.182,269.77,313.988z"></path>
            <path className="fill-yellow" d="M43.636,290.327v23.79h79.257c-8.404-31.935-13.705-67.814-15.192-104.21L43.636,290.327z"></path>
        </g>
        <path className="fill-blue" d="M196.331,21.85c-19.523,0-67.362,58.053-67.362,165.624c0,44.218,5.883,88.76,16.549,126.578h101.754
	c10.667-37.818,16.549-82.424,16.549-126.578C263.693,79.903,215.855,21.85,196.331,21.85z"></path>
        <g>
            <path className="fill-darkblue" d="M368.42,279.725l-83.135-104.598C281.794,66.327,232.275,0,196.331,0s-85.463,66.327-88.954,175.127
		L24.242,279.725c-1.552,1.939-2.392,4.331-2.392,6.788v38.465c0,6.012,4.848,10.925,10.925,10.925h109.899v26.44
		c0,6.012,4.848,10.925,10.925,10.925s10.925-4.848,10.925-10.925v-26.44h20.816v45.834c0,6.012,4.848,10.925,10.925,10.925
		c6.077,0,10.925-4.848,10.925-10.925v-45.834h20.945v26.44c0,6.012,4.848,10.925,10.925,10.925c6.012,0,10.925-4.848,10.925-10.925
		v-26.44h109.899c6.012,0,10.925-4.849,10.925-10.925v-38.465C370.812,284.057,369.972,281.665,368.42,279.725z M349.026,313.988
		H269.77c8.404-31.935,13.705-67.814,15.192-104.21l64,80.549v23.661H349.026z M145.455,313.988
		c-10.667-37.883-16.549-82.424-16.549-126.578c0-107.572,47.838-165.624,67.362-165.624s67.362,58.053,67.362,165.624
		c0,44.218-5.883,88.76-16.549,126.578H145.455z M43.636,313.988v-23.79l64-80.42c1.552,36.396,6.788,72.339,15.192,104.21H43.636z"></path>
            <path className="fill-darkblue" d="M196.331,174.093c-23.402,0-42.473-19.071-42.473-42.473s19.071-42.473,42.473-42.473
		s42.473,19.071,42.473,42.473S219.733,174.093,196.331,174.093z M196.331,110.869c-11.442,0-20.687,9.244-20.687,20.687
		s9.244,20.687,20.687,20.687c11.442,0,20.687-9.244,20.687-20.687S207.774,110.869,196.331,110.869z"></path>
        </g>
        <path className="fill-white" d="M196.331,110.869c-11.442,0-20.687,9.244-20.687,20.687s9.244,20.687,20.687,20.687
	c11.442,0,20.687-9.244,20.687-20.687S207.774,110.869,196.331,110.869z"></path>
    </svg>
);


export const FeatureMakerOneStar = () => (
    <svg fill="#000000" width="50" height="50" viewBox="0 0 24 24" id="date-forward" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" className="icon flat-line">
        <path id="secondary" d="M3,9H21a0,0,0,0,1,0,0V20a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V9A0,0,0,0,1,3,9Z" style={{ fill: 'rgb(44, 169, 188)', strokeWidth: 2 }}></path>
        <path id="primary" d="M20,21H4a1,1,0,0,1-1-1V9H21V20A1,1,0,0,1,20,21ZM21,5a1,1,0,0,0-1-1H4A1,1,0,0,0,3,5V9H21ZM16,3V6M8,3V6" style={{ fill: 'none', stroke: 'rgb(0, 0, 0)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2 }}></path>
        <path id="primary-2" data-name="primary" d="M15,17a4,4,0,0,0-8,0" style={{ fill: 'none', stroke: 'rgb(0, 0, 0)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2 }}></path>
        <polyline id="primary-3" data-name="primary" points="17 14 15 17 12 16" style={{ fill: 'none', stroke: 'rgb(0, 0, 0)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2 }}></polyline>
    </svg>
)

export const ConsultantOneStar = () => (
    <svg height="50" width="50" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
        <path fill="#FFBAA8" d="M67.155,244.034H55.649c-14.112,0-25.554-11.44-25.554-25.554v-24.344c0-14.112,11.44-25.554,25.554-25.554h11.506c12.598,0,22.809,10.213,22.809,22.809v29.832C89.965,233.821,79.753,244.034,67.155,244.034z"></path>
        <path fill="#0BBBDA" d="M109.541,330.996H10.52V283.9c0-22.017,17.849-39.866,39.866-39.866h19.288c22.017,0,39.866,17.849,39.866,39.866V330.996z"></path>
        <path fill="#FFBAA8" d="M431.278,244.034h-11.506c-14.113,0-25.554-11.44-25.554-25.554v-24.344c0-14.112,11.44-25.554,25.554-25.554h11.506c12.598,0,22.809,10.213,22.809,22.809v29.832C454.089,233.821,443.876,244.034,431.278,244.034z"></path>
        <g>
            <path fill="#0BBBDA" d="M473.664,330.996h-99.021V283.9c0-22.017,17.849-39.866,39.866-39.866h19.288c22.017,0,39.866,17.849,39.866,39.866V330.996z"></path>
            <path fill="#0BBBDA" d="M151.621,369.054v-56.282c0-32.391,29.553-49.181,62.115-49.181h56.713c32.562,0,62.115,16.79,62.115,49.181v56.282H151.621z"></path>
        </g>
        <path fill="#FFBAA8" d="M245.511,263.591h-6.838c-21.643,0-34.979-17.544-34.979-39.187v-18.41c0-21.643,13.336-39.187,34.979-39.187h6.838c21.643,0,34.979,17.544,34.979,39.187v18.41C280.49,246.046,267.154,263.591,245.511,263.591z"></path>
        <g>
            <polygon fill="#ECF0F1" points="275.685,263.591 242.092,316.366 208.499,263.591"></polygon>
            <path fill="#ECF0F1" d="M378.611,328.575c16.776-25.629,26.54-56.262,26.54-89.18c0-90.055-73.004-163.059-163.059-163.059S79.033,149.341,79.033,239.396s73.004,163.059,163.059,163.059c31.919,0,61.689-9.18,86.833-25.03l123.286,123.286l49.268-49.268L378.611,328.575z M242.092,355.115c-63.808,0-115.719-51.912-115.719-115.719s51.912-115.719,115.719-115.719s115.719,51.912,115.719,115.719S305.9,355.115,242.092,355.115z"></path>
        </g>
        <g>
            <path fill="#231F20" d="M508.919,444.004L406.43,341.516h67.234c5.809,0,10.52-4.711,10.52-10.52v-47.097c0-18.725-10.276-35.086-25.477-43.771c3.717-5.376,5.902-11.889,5.902-18.905v-29.831c0-18.378-14.952-33.329-33.33-33.329h-11.506c-7.815,0-15.049,2.509-20.963,6.749c-27.947-58.489-87.689-98.996-156.719-98.996c-68.639,0-128.097,40.049-156.243,98.005c-5.336-3.629-11.769-5.758-18.694-5.758H55.649c-19.891,0-36.074,16.183-36.074,36.073v24.344c0,7.877,2.545,15.168,6.846,21.107C10.699,248.124,0,264.786,0,283.899v47.097c0,5.809,4.71,10.52,10.52,10.52h91.305c31.596,43.28,82.699,71.459,140.268,71.459c29.986,0,59.261-7.693,85.186-22.32l117.494,117.495c2.055,2.053,4.747,3.081,7.439,3.081s5.384-1.028,7.439-3.081l49.268-49.268C513.027,454.773,513.027,448.112,508.919,444.004z M463.144,320.476h-67.571c10.825-20.448,17.412-42.808,19.419-65.922h18.805c16.182,0,29.346,13.165,29.346,29.346V320.476z M419.773,179.103h11.506c6.776,0,12.29,5.512,12.29,12.289v29.831c0,6.776-5.513,12.29-12.29,12.29h-11.506c-1.472,0-2.891-0.222-4.236-0.618c-0.605-16.344-3.478-32.114-8.317-47.01C409.911,181.804,414.53,179.103,419.773,179.103z M40.615,194.136c0-8.29,6.744-15.033,15.034-15.033h11.506c4.264,0,8.025,2.184,10.23,5.49c-5.147,15.432-8.178,31.821-8.757,48.823c-0.484,0.058-0.973,0.097-1.473,0.097H55.649c-8.29,0-15.034-6.744-15.034-15.034C40.615,218.479,40.615,194.136,40.615,194.136z M21.04,320.476v-36.577c0-16.182,13.165-29.346,29.346-29.346h18.796c2.055,23.638,8.865,45.932,19.471,65.922H21.04V320.476z M452.211,485.833L336.363,369.985c-2.031-2.03-4.725-3.081-7.442-3.081c-1.934,0-3.88,0.531-5.607,1.621c-24.293,15.315-52.379,23.409-81.222,23.409c-84.11,0-152.539-68.429-152.539-152.539S157.982,86.856,242.092,86.856c84.11,0,152.539,68.429,152.539,152.539c0,53.968-28.026,104.296-73.485,132.414c-2.518,1.539-4.286,4.008-4.858,6.855c-0.572,2.848,0.196,5.808,2.07,8.044l115.082,115.082L452.211,485.833z"></path>
            <path fill="#231F20" d="M242.092,110.763c-70.992,0-128.612,57.621-128.612,128.612s57.62,128.612,128.612,128.612s128.612-57.621,128.612-128.612S313.084,110.763,242.092,110.763z M242.092,342.455c-56.989,0-103.08-46.09-103.08-103.08s46.091-103.08,103.08-103.08s103.08,46.09,103.08,103.08S299.081,342.455,242.092,342.455z"></path>
        </g>
    </svg>
);

export const SelsManager = () => (
    <svg width="50" height="50" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
        <path fill="#67BFFF" d="M413.318,303.035c0,113.956-92.714,206.659-206.659,206.659C92.704,509.694,0,416.99,0,303.035
	C0,189.089,92.704,96.376,206.659,96.376C320.605,96.376,413.318,189.089,413.318,303.035z"></path>
        <g>
            <path fill="#FFFFFF" d="M206.659,138.392c-90.787,0-164.641,73.855-164.641,164.641s73.855,164.641,164.641,164.641
		c90.777,0,164.641-73.855,164.641-164.641S297.436,138.392,206.659,138.392z M206.659,438.07
		c-74.463,0-135.035-60.583-135.035-135.035s60.572-135.035,135.035-135.035c74.453,0,135.025,60.583,135.025,135.035
		S281.112,438.07,206.659,438.07z"></path>
            <path fill="#FFFFFF" d="M206.659,211.71c-50.362,0-91.324,40.963-91.324,91.324s40.963,91.324,91.324,91.324
		c50.352,0,91.325-40.963,91.325-91.324S257.011,211.71,206.659,211.71z M206.659,364.753c-34.027,0-61.718-27.691-61.718-61.718
		s27.691-61.718,61.718-61.718s61.718,27.691,61.718,61.718S240.686,364.753,206.659,364.753z"></path>
        </g>
        <path fill="#0088FF" d="M413.318,303.035c0,113.956-92.714,206.659-206.659,206.659V96.376
	C320.605,96.376,413.318,189.089,413.318,303.035z"></path>
        <g>
            <path fill="#D9D8DB" d="M297.984,303.035c0,50.361-40.973,91.324-91.325,91.324v-29.607
		c34.027,0,61.718-27.691,61.718-61.718s-27.691-61.718-61.718-61.718v-29.607C257.011,211.71,297.984,252.672,297.984,303.035z"></path>
            <path fill="#D9D8DB" d="M371.3,303.035c0,90.787-73.865,164.641-164.641,164.641v-29.607
		c74.453,0,135.025-60.583,135.025-135.035s-60.572-135.035-135.025-135.035v-29.607C297.436,138.392,371.3,212.247,371.3,303.035z"></path>
        </g>
        <polygon fill="#FD7052" points="512,76.431 313.573,274.859 206.671,307.615 206.656,307.629 239.448,200.72 383.52,56.663 
	437.877,2.306 "></polygon>
        <polygon fill="#FF4F18" points="512,76.431 313.573,274.859 206.671,307.615 474.927,39.358 "></polygon>
        <polygon fill="#FFDBA8" points="313.58,274.858 313.575,274.863 238.467,297.876 206.671,307.62 206.654,307.625 
	216.413,275.824 239.448,200.726 "></polygon>
        <polygon fill="#FFC473" points="313.58,274.858 313.575,274.863 238.467,297.876 206.671,307.62 276.506,237.784 "></polygon>
        <polygon fill="#57555C" points="238.467,297.876 206.671,307.62 206.654,307.625 216.413,275.824 "></polygon>
        <rect x="413.427" y="12.437" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 813.1192 -207.1051)" fill="#67BFFF" width="72.052" height="104.826"></rect>
        <rect x="415.977" y="30.23" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 795.3254 -164.1483)" fill="#FFFFFF" width="31.363" height="104.826"></rect>
        <rect x="431.959" y="57.154" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 857.8501 -188.5973)" fill="#0088FF" width="72.052" height="52.429"></rect>
        <polygon fill="#3C3A41" points="227.44,286.85 238.467,297.876 206.671,307.62 "></polygon>
        <rect x="434.507" y="74.955" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 840.0579 -145.6249)" fill="#D9D8DB" width="31.363" height="52.429"></rect>
    </svg>
);



export const OneStartGuid = () => (
    <svg height="50" width="50" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
        <g>
            <path fill="#805333" d="M273.655,406.069c0-58.5,47.431-105.931,105.931-105.931c23.093,0,44.385,7.468,61.793,20.012
		V28.734c0-6.118-4.961-11.079-11.079-11.079H308.966h-35.31v26.483h-79.448V17.655h-35.31H37.561
		c-6.118,0-11.079,4.961-11.079,11.079v472.188c0,6.118,4.961,11.079,11.079,11.079h342.025
		C321.086,512,273.655,464.569,273.655,406.069"></path>
            <path fill="#F8EDCD" d="M273.655,406.069c0-58.5,47.431-105.931,105.931-105.931c6.029,0,11.9,0.618,17.655,1.589V61.793
		h-61.793v8.828H132.414v-8.828H70.621v406.069h223.047C281.123,450.454,273.655,429.153,273.655,406.069"></path>
            <polygon fill="#95A5A5" points="194.207,44.138 273.655,44.138 273.655,0 194.207,0 	"></polygon>
            <polygon fill="#BDC3C7" points="273.655,17.655 273.655,44.138 194.207,44.138 194.207,17.655 132.414,17.655 
		132.414,70.621 335.448,70.621 335.448,17.655 	"></polygon>
            <g>
                <path fill="#B5AA8D" d="M176.552,335.448h-44.138c-4.873,0-8.828-3.946-8.828-8.828c0-4.882,3.955-8.828,8.828-8.828
			h44.138c4.873,0,8.828,3.946,8.828,8.828C185.379,331.502,181.425,335.448,176.552,335.448"></path>
                <path fill="#B5AA8D" d="M264.828,335.448h-17.655c-4.873,0-8.828-3.946-8.828-8.828c0-4.882,3.955-8.828,8.828-8.828
			h17.655c4.873,0,8.828,3.946,8.828,8.828C273.655,331.502,269.7,335.448,264.828,335.448"></path>
                <path fill="#B5AA8D" d="M211.862,335.448c-2.383,0-4.59-0.892-6.268-2.56c-1.677-1.677-2.56-3.884-2.56-6.268
			c0-2.295,0.971-4.599,2.56-6.268c0.883-0.794,1.854-1.412,2.913-1.854c3.266-1.412,7.15-0.618,9.622,1.854
			c1.589,1.668,2.56,3.972,2.56,6.268c0,2.383-0.883,4.59-2.56,6.268C216.452,334.557,214.246,335.448,211.862,335.448"></path>
                <path fill="#B5AA8D" d="M229.517,379.586h-61.793c-4.873,0-8.828-3.946-8.828-8.828c0-4.882,3.955-8.828,8.828-8.828
			h61.793c4.873,0,8.828,3.946,8.828,8.828C238.345,375.64,234.39,379.586,229.517,379.586"></path>
                <path fill="#B5AA8D" d="M132.414,379.586c-2.383,0-4.59-0.892-6.268-2.56c-1.677-1.677-2.56-3.884-2.56-6.268
			c0-2.295,0.971-4.599,2.56-6.268c0.883-0.794,1.854-1.412,2.913-1.854c3.266-1.412,7.15-0.618,9.622,1.854
			c1.589,1.668,2.56,3.972,2.56,6.268c0,2.383-0.883,4.59-2.56,6.268C137.004,378.695,134.797,379.586,132.414,379.586"></path>
            </g>
            <path fill="#C03A2B" d="M132.405,264.828c-1.774,0-3.566-0.538-5.12-1.651c-3.972-2.825-4.89-8.342-2.057-12.306
		l44.138-61.793c1.386-1.942,3.487-3.231,5.835-3.593c2.348-0.371,4.74,0.23,6.647,1.66l27.842,20.877l30.137-45.197
		c2.322-3.469,6.744-4.82,10.628-3.302l38.727,15.492L329.207,135c3.452-3.452,9.031-3.452,12.482,0s3.452,9.031,0,12.482
		l-44.138,44.138c-2.489,2.498-6.241,3.257-9.525,1.96l-37.482-15.007l-31.338,47.016c-1.342,2.013-3.46,3.39-5.853,3.796
		c-2.401,0.415-4.846-0.177-6.788-1.633l-28.081-21.063l-38.886,54.44C137.878,263.539,135.159,264.828,132.405,264.828"></path>
            <path fill="#13A085" d="M485.517,406.069c0,58.5-47.431,105.931-105.931,105.931s-105.931-47.431-105.931-105.931
		s47.431-105.931,105.931-105.931S485.517,347.569,485.517,406.069"></path>
            <path fill="#FFFFFF" d="M377.694,449.577c-2.03,0-4.017-0.697-5.597-2.013l-42.249-34.675
		c-3.769-3.09-4.317-8.66-1.227-12.42c3.098-3.752,8.66-4.326,12.429-1.218l34.86,28.61l49.399-70.974
		c2.772-4.008,8.28-5.005,12.288-2.198c3.999,2.781,4.988,8.28,2.198,12.288l-54.855,78.813c-1.404,2.022-3.593,3.363-6.038,3.699
		C378.497,449.551,378.091,449.577,377.694,449.577"></path>
        </g>
    </svg>
)

export const AnStartStrategy = () => (
    <svg fill="#000000" height="50" width="50" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520.328 520.328" xmlSpace="preserve">
        <g>
            <path d="M258.138,167.817c24.646,0,44.696-20.05,44.696-44.695c0-24.646-20.051-44.696-44.696-44.696
		c-24.64,0-44.685,20.051-44.685,44.696C213.452,147.767,233.498,167.817,258.138,167.817z"></path>
            <path d="M330.902,194.205c32.014-29.297,35.263-35.732,37.01-39.191c7.647-15.143,11.489-50.376,12.182-57.285
		c1.047-10.433-6.591-19.774-17.028-20.823c-0.63-0.063-1.268-0.095-1.898-0.095c-9.81,0-17.945,7.36-18.927,17.119
		c-1.608,15.979-5.15,37.064-8.1,43.541c-3.728,5.362-21.901,22.558-41.064,39.66h-65.824c-19.163-17.104-37.338-34.3-41.066-39.662
		c-2.95-6.469-6.491-27.554-8.098-43.534c-0.979-9.764-9.108-17.127-18.911-17.127c-0.635,0-1.278,0.032-1.913,0.096
		c-10.438,1.047-18.077,10.389-17.031,20.824c0.692,6.91,4.534,42.146,12.181,57.285c1.745,3.456,4.993,9.89,37.013,39.192
		c8.893,8.139,17.39,15.659,22.924,20.512v209.016c0,10.911,8.876,19.787,19.786,19.787c10.911,0,19.787-8.876,19.787-19.787
		V314.118h12.504v109.615c0,10.911,8.876,19.787,19.787,19.787c10.911,0,19.787-8.876,19.787-19.787l-0.066-205.484
		C308.472,214.301,319.39,204.741,330.902,194.205z"></path>
            <path d="M162.338,244.228c-2.105-4.87-6.857-8.006-12.147-8.131c-0.033-0.001-47.836-0.009-47.916-0.009
		c-4.042,0-7.788,1.797-10.31,4.781L89.426,235c-1.901-4.397-6.226-7.237-11.018-7.237c-0.111,0-43.031,0-43.276,0
		c-5.852,0-9.117,2.841-11.017,7.237l-23.122,53.47c-1.273,2.941-1.323,6.202-0.144,9.181c1.181,2.979,3.451,5.319,6.391,6.59
		c1.516,0.657,3.116,0.99,4.758,0.99c4.79,0,9.113-2.841,11.014-7.237l4.859-11.237v103.528c0,7.284,5.928,13.21,13.213,13.21
		c7.284,0,13.21-5.926,13.21-13.21v-67.833h5.572v67.833c0,7.284,5.927,13.21,13.211,13.21c7.284,0,13.211-5.926,13.211-13.21
		v-71.546c0.961-1.069,1.767-2.296,2.357-3.662l5.397-12.483c0.023,0.017,0.045,0.037,0.067,0.055v116.225
		c0,8.192,6.666,14.857,14.859,14.857c8.192,0,14.857-6.665,14.857-14.857v-77.101h6.266v77.101c0,8.192,6.665,14.857,14.858,14.857
		c8.192,0,14.857-6.665,14.857-14.857l0.035-112.43l3.733,8.634c2.139,4.944,7.002,8.139,12.389,8.139
		c1.844,0,3.644-0.374,5.35-1.112c3.308-1.429,5.86-4.061,7.188-7.412c1.328-3.351,1.27-7.018-0.16-10.325L162.338,244.228z"></path>
            <path d="M128.304,231.144c18.751,0,34.005-15.255,34.005-34.006c0-18.75-15.254-34.004-34.005-34.004
		c-18.746,0-33.997,15.254-33.997,34.004C94.307,215.889,109.558,231.144,128.304,231.144z"></path>
            <path d="M58.274,223.365c16.672,0,30.235-13.563,30.235-30.235c0-16.671-13.563-30.234-30.235-30.234
		c-16.668,0-30.228,13.564-30.228,30.234C28.047,209.801,41.606,223.365,58.274,223.365z"></path>
            <path d="M358.019,197.138c0,18.751,15.254,34.006,34.005,34.006c18.746,0,33.997-15.255,33.997-34.006
		c0-18.75-15.251-34.004-33.997-34.004C373.273,163.134,358.019,178.388,358.019,197.138z"></path>
            <path d="M519.336,288.47L496.213,235c-1.899-4.397-6.224-7.237-11.017-7.237c-0.245,0-43.165,0-43.276,0
		c-4.792,0-9.116,2.841-11.018,7.237l-2.539,5.87c-2.522-2.984-6.268-4.781-10.31-4.781c-0.08,0-47.883,0.009-47.916,0.009
		c-5.858,0-10.042,3.261-12.147,8.131l-26.005,60.137c-1.431,3.308-1.488,6.975-0.16,10.325c1.327,3.351,3.88,5.983,7.187,7.412
		c1.707,0.738,3.507,1.112,5.351,1.112c5.387,0,10.25-3.194,12.389-8.139l3.733-8.634l0.035,112.43
		c0,8.192,6.665,14.857,14.858,14.857c8.193,0,14.858-6.665,14.858-14.857v-77.101h6.266v77.101c0,8.192,6.665,14.857,14.857,14.857
		c8.193,0,14.859-6.665,14.859-14.857V302.647c0.022-0.018,0.044-0.037,0.067-0.055l5.397,12.483
		c0.591,1.366,1.396,2.593,2.357,3.662v71.546c0,7.284,5.927,13.21,13.211,13.21s13.211-5.926,13.211-13.21v-67.833h5.572v67.833
		c0,7.284,5.926,13.21,13.21,13.21c7.285,0,13.213-5.926,13.213-13.21V286.755l4.859,11.237c1.9,4.396,6.224,7.237,11.014,7.237
		c1.642,0,3.242-0.333,4.756-0.989c2.941-1.271,5.212-3.612,6.393-6.591C520.658,294.671,520.608,291.411,519.336,288.47z"></path>
            <path d="M462.054,223.365c16.668,0,30.228-13.563,30.228-30.235c0-16.671-13.56-30.234-30.228-30.234
		c-16.672,0-30.235,13.564-30.235,30.234C431.818,209.801,445.382,223.365,462.054,223.365z"></path>
        </g>
    </svg>
)

export const ExamsResults = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#000000" className="bi bi-mortarboard-fill" viewBox="0 0 16 16">
        <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z"></path>
        <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z"></path>
    </svg>
)