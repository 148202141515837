import React, { useEffect, useState } from 'react'
import ProfileImage from '../../../assets/icons/profileImage.png'
const TeamList = (props) => {
    const { List } = props
    const direction = { 'direction': 'rtl' }
    return (
        <div className='w-full lg:w-[calc(100vw-350px)] 2xl:w-[calc(100vw-350px)] min-h-screen'>
            <div className='w-full px-[0px] sm:px-[20px] py-5 sm:py-10 lg:px-[20px] mt-10 lg:mt-0'>
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4  gap-4'>
                    {
                        List.map((item, index) => (
                            <div className='w-full rounded-none sm:rounded-lg border-2 border-[#33779e] box-shadow-peopleTeam'>
                                <div className='w-full flex justify-center items-center py-5'>
                                    <div className='w-[100px] h-[100px] rounded-full bg-[rgba(255,255,255,1)] border-2 border-[#02363fa8] p-1'>
                                        <img src={item.profileImage} className='w-full h-full rounded-full' />
                                    </div>
                                </div>
                                <div style={direction} className='w-full flex justify-around border-b-[1px] py-2'>
                                    <span className='w-1/2 text-center'>نام :</span>
                                    <span className='w-1/2 text-center'>{item.firstname}</span>
                                </div>
                                <div style={direction} className='w-full flex justify-around border-b-[1px] py-2'>
                                    <span className='w-1/2 text-center'>نام خانوادگی :</span>
                                    <span className='w-1/2 text-center'>{item.lastname}</span>
                                </div>
                                <div style={direction} className='w-full flex justify-around border-b-[1px] py-2'>
                                    <span className='w-1/2 text-center'>نام کاربری :</span>
                                    <span className='w-1/2 text-center persian-number'>{item.username}</span>
                                </div>
                                <div style={direction} className='w-full flex justify-around border-b-[1px] py-2'>
                                    <span className='w-1/2 text-center'>قیمت :</span>
                                    <span className='w-1/2 text-center persian-number'>{item.totalPurchase.toLocaleString()}</span>
                                </div>
                                <div style={direction} className='w-full flex justify-around border-b-[1px] py-2'>
                                    <span className='w-1/2 text-center'>واحد :</span>
                                    <span className='w-1/2 text-center persian-number'>{item.unit.toLocaleString()}</span>
                                </div>
                                <div style={direction} className='w-full flex justify-around py-2'>
                                    <span className='w-1/2 text-center'>سطح :</span>
                                    <span className='w-1/2 text-center persian-number'>{item.level.toLocaleString()}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default TeamList
