import React, { useEffect, useState } from 'react';
import DropDownImg from '../../assets/icons/dropdown.png';
import DropDownImgBlack from '../../assets/icons/dropdownblack.png';
const MenuComponent = ({
    Lists,
    C1,
    C2,
    C3,
    C4,
    C5,
    C6,
    SubMenuLoaderFunc,
}) => {
    const [DropDown, setDropDown] = useState(null); // Initialize as null for no open dropdown
    const pathname = window.location.pathname;

    useEffect(() => {
        switch (pathname) {
            case '/':
                setDropDown(1);
                break;
            case '/shematic/tree':
            case '/shematic/linear':
                setDropDown(2);
                break;
            case '/commission/received':
                setDropDown(3);
                break;
            case '/commission/latest':
                setDropDown(3);
                break;
            case '/team/activitist':
                setDropDown(4);
                break;
            case '/team/peoplewhodonthavepurchase':
                setDropDown(4);
                break;
            case '/team/goodsdeliveryremittance':
                setDropDown(6);
                break;
            case '/team/goods/order':
                setDropDown(6);
                break;
            case '/vista':
                setDropDown(5);
                break;
            case '/sibyar':
                setDropDown(5);
                break;
            default:
                setDropDown(null); // Set to null so no dropdown is open by default
                break;
        }
    }, [pathname]);

    const handleToggleDropdown = (itemId) => {
        setDropDown(prevState => prevState === itemId ? null : itemId); // Toggle dropdown
    };

    return (
        <div className={C1}>
            <ul className={C2}>
                {Lists.map((item, index) => (
                    <li
                        onClick={() => handleToggleDropdown(item.id)}
                        onMouseLeave={() => SubMenuLoaderFunc([], item.id)}
                        key={index}
                        className={`w-full mt-[2px] py-3 ${item.id === DropDown ? 'bg-[#176B87] text-[#ffffff]' : 'bg-[rgba(255,255,255,0.9)]'} hover:bg-[#176B87] hover:text-[#ffffff]`}
                    >
                        <a href={item.href} className='w-full'>
                            <div className={`w-full relative flex cursor-pointer justify-end items-center`}>
                                <span className={``}>{item.name}</span>
                                <span className={`mx-4`}>{item.icon}</span>

                                {/* Dropdown icon for items with children */}
                                {item.options.length > 0 && (
                                    <span
                                        className={`mx-5 ${index === 0 ? C4 : C5} absolute left-0 transform transition-transform duration-300 ${item.id === DropDown ? 'rotate-90' : 'rotate-0'}`}
                                    >
                                        {item.id === DropDown ? <img className='w-[15px] -rotate-90' src={DropDownImg} alt='safiran dropdown' /> : <img className='w-[15px] rotate-90' src={DropDownImgBlack} alt='safiran dropdown' />}
                                    </span>
                                )}
                            </div>
                        </a>
                        <div className='w-full'>
                            {item.options.length > 0 && (
                                <ul className={`w-full`}>
                                    {item.options.map((ite, idx) => (
                                        <li key={idx} className='w-full'>
                                            <a href={ite.href} className={`w-full px-10 mt-5 items-center cursor-pointer justify-end ${item.id === DropDown ? 'flex' : 'hidden'}`}>
                                                <span className='px-5'>{ite.name}</span>
                                                <span className={`${idx === 2 ? '-translate-x-[5px]' : ''}`}>{ite.icon}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MenuComponent;
