import React from 'react'

const TitleComponent = ({
    Fname,
    Lname,
    C1,
    C2,
    C3,
    UserID,
    Warning,
    Time,
    Identification,
    WarningText,
    TimeText,
}) => {
    return (
        <>
            <div className={C1}>
                <span className={C2}>{Lname}</span>
                <span className={C3}>{Fname}</span>
            </div>
            <div className={UserID}>
                {Identification}
            </div>
            <div className={Warning}>
                {WarningText}
            </div>
            <div className={Time}>
                {TimeText}
            </div>
        </>
    )
}

export default TitleComponent
