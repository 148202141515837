import React from 'react'
import { Collections } from '../../../data/CollectionComponent'
import Return from '../../../assets/icons/Return2.png';
const ResponsiveCollection = (props) => {
    const { BackButton } = props
    return (
        <div className='w-full min-h-screen relative'>
            <div className='w-full overflow-y-scroll absolute bottom-0 h-[calc(100vh-60px)]'>
                <div className='w-full grid sm:grid-cols-2 gap-5 mt-5 px-3 pb-10'>
                    {
                        Collections.map((item, index) => {
                            return (
                                <div key={index} className='w-full py-5 px-3 box-shadow-peopleTeam rounded-[50px] border-[1px] border-[#2e2d2d5e]'>
                                    <div className='w-full'>
                                        <div className='w-full flex justify-between py-3 px-3'>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{item.fullname}</div>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{'نام خانوادگی'}</div>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='w-full flex justify-between py-3 px-3'>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{item.username}</div>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{'نام کاربری'}</div>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='w-full flex justify-between py-3 px-3'>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{item.collectiveRemittance}</div>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{'فاکتور تجمیع شده'}</div>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='w-full flex justify-between py-3 px-3'>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{item.code}</div>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{'کد'}</div>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='w-full flex justify-between py-3 px-3'>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{item.status}</div>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{'وضعیت'}</div>
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <div className='w-full flex justify-between py-3 px-3'>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{item.date}</div>
                                            <div className='persian-number text-[15px] sm:text-[14px]'>{'تاریخ'}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
            <div onClick={() => BackButton(true)} className='fixed bottom-1 left-1 w-[50px] h-[50px] p-2 rounded-full bg-[#3b729e]'>
                <img src={Return} />
            </div>
        </div>
    )
}

export default ResponsiveCollection
