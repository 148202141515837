import React, { useState } from 'react'
import { CommissionReceivedList } from '../../data/CommissionReceived';
import Calendar from '../../assets/icons/calendar.png';
import Cancel from '../../assets/icons/cancel.png';
import Success from '../../assets/icons/success.png';
const CommissionReceived = (props) => {
    const {
        width,
        Loading,
    } = props
    const [modalStatus, setModalStatus] = useState(false)
    const [ListsInfo, setListsInfo] = useState([])
    const CreatingListFunction = (object) => {
        setListsInfo(object.Detail)
        setModalStatus(true)
    }

    return (
        <div className='w-full mt-0 lg:w-[calc(100vw-350px)] h-screen overflow-x-hidden card relative'>
            <div className='xl:container'>
                {
                    modalStatus &&
                    <div className='absolute top-0 left-0 w-full h-full flex  justify-center items-center z-10 bg-[rgba(0,0,0,0.5)]'>
                        {
                            width >= 800 &&
                            <div className='w-[80%] h-[80%] rounded-3xl bg-white px-5 overflow-y-scroll card'>
                                <div className='w-full h-[60px] flex items-center justify-between border-b-[2px] border-[rgba(0,0,0,0.5)]'>
                                    <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>وضعیت</div>
                                    <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>تا</div>
                                    <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>از</div>
                                    <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>پلن</div>
                                    <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>واحد</div>
                                    <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl relative'>
                                        مبلغ
                                        <div onClick={() => setModalStatus(false)} className='absolute -top-2 -right-3 w-[30px] h-[30px] rounded-full bg-[red] cursor-pointer'>
                                            <div className='w-full h-full relative rounded-full flex justify-center items-center'>
                                                <img src={Cancel} alt='Safiran' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    ListsInfo.map((ite, idx) => (
                                        <div key={idx} className='w-full CommissionListBoxShadow h-[50px] mt-5 rounded-[10px]'>
                                            <div className='w-full h-full flex justify-between items-center'>
                                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.Status ? <img src={Success} className='w-[30px]' alt='Success' /> : <img src={Cancel} className='w-[30px]' alt='Cancel' />}</div>
                                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.End}</div>
                                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.From}</div>
                                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.Plan}</div>
                                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.Unit}</div>
                                                <div className='flex-1 flex justify-center sm:text-sm  md:text-md xl:text-lg 2xl:text-xl'>{ite.Price}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        {
                            width < 800 &&
                            <div className='w-full h-full relative'>
                                <div className='w-full relative'>
                                    <div onClick={() => setModalStatus(false)} className='absolute w-[40px] h-[40px] cursor-pointer rounded-full bg-[rgba(255,255,255,1)] right-10 top-20'>
                                        <img src={Cancel} alt='Safiran' />
                                    </div>
                                </div>
                                <div className='w-full flex justify-center relative translate-y-[140px]'>
                                    <ul className='w-full grid sm:grid-cols-3 gap-5 px-10'>
                                        {
                                            ListsInfo.map((it, idx) => {
                                                return (<>
                                                    <li className='w-full flex justify-end bg-[rgba(255,255,255,1)] rounded-xl py-3 px-5'>
                                                        <div className='text-end'>
                                                            <div className='text-black'>
                                                                <span className='mx-2'>مبلغ : </span>
                                                                <span className='text-[12px] font-semibold'>{it.Price}</span>
                                                            </div>
                                                            <div className='text-black mt-3'>
                                                                <span className='mx-2'>واحد : </span>
                                                                <span className='text-[12px] font-semibold'>{it.Unit}</span>
                                                            </div>
                                                            <div className='text-black mt-3'>
                                                                <span className='mx-2'>پلن : </span>
                                                                <span className='text-[12px] font-semibold'>{it.Plan}</span>
                                                            </div>
                                                            <div className='text-black mt-3'>
                                                                <span className='mx-2'>از : </span>
                                                                <span className='text-[12px] font-semibold'>{it.From}</span>
                                                            </div>
                                                            <div className='text-black mt-3'>
                                                                <span className='mx-2'>تا : </span>
                                                                <span className='text-[12px] font-semibold'>{it.End}</span>
                                                            </div>
                                                            <div className='w-full text-black flex justify-end items-center mt-3'>
                                                                <span>{it.Status ? <img src={Success} className='w-[30px]' alt='Success' /> : <img src={Cancel} className='w-[30px]' alt='Cancel' />}</span>
                                                                <span className='mx-2'>: وضعیت</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </>)
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className='absolute top-0 left-0 w-full h-full z-0 flex items-center justify-center'>
                    <div className='w-full h-full p-10 flex items-center justify-center'>
                        <div className='w-full h-full mb-10 py-20 lg:py-0'>
                            <ul className='w-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-3' style={{ 'direction': 'rtl' }}>
                                {
                                    CommissionReceivedList.map((item, index) => (
                                        <li onClick={() => CreatingListFunction(item)} key={index} className='w-full h-[60px] cursor-pointer flex rounded-[100px] bg-[#107bc2]'>
                                            <div className='h-full flex items-center px-1'>
                                                <img src={Calendar} className='w-[60px] p-1 rounded-full bg-[#236f88] border-[3px] border-[rgba(255,255,255,1)]' />
                                            </div>
                                            <div className='w-full h-full flex items-center justify-start rounded-l-[100px]'>
                                                <span className='text-[rgba(255,255,255,1)] sm:textt-sm md:text-md lg:text-base  xl:text-xl 2xl:text-md mx-4'>{item.Month}</span>
                                                <span className='text-[rgba(255,255,255,1)] sm:textt-sm md:text-md lg:text-base  xl:text-xl 2xl:text-md mx-2'>{item.Year}</span>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommissionReceived
