import { data } from 'autoprefixer'
import Serial from '../assets/icons/serial.png'
export const Remittance = [
    {
        date: '21:42:42 30-07-1403',
        name: 'محمدرضا بهرامی راد',
        RemittanceNumber: '39859',
        RemittanceImage: Serial,
        NationalCode: '1830567345',
        OrderStatus: 'در حال پردازش',
        PostalCode: '1998135891',
        TelephoneNumber: '989122770733',
        Address: 'تهران - تهران - تهران سعادت آباد کوچه شهید مجید نامی کوچه آریا پلاک یک واحد دو',
        RowNumber: 1,
        GoogsCode: 91601100,
        GoodsTitle: 'اسپری ضدبوی کفش',
        deliveryMethods: {
            deliveryMethodNames: {
                Name1: 'انبار',
                Name2: 'ارسال پستی / انبار مرکزی',
            },
            deliveryMethodCodes: {
                Codes1: {
                    Code1: 'خ',
                    Code2: 'ت',
                    Code3: 'م',
                },
                Codes2: {
                    Code1: '1',
                    Code2: '0',
                    Code3: '1',
                }
            }
        },
        Remaining: 1,
        Price: 1500000,
        TotalPrice: 1500000,
        FinalSummition: 1920864,
        PriceUnit: 'ریال',
        SumOfTotalOfCounts: 1,
        TotalWeight: 0,
        WeightUnit: 'گرم',
        GoodsDeliveryPrice: 600000,
        ValueAddedTax: 174624,
        RetailDiscount: 5760,
        DeliveryMethod: {
            Methods1: 'اینجانب',
            Methods2: 'در تاریخ',
            Methods3: 'با علم و اطلاع کامل از کلیه مقررات و قوانین بازاریابی شبکه ای و قوانین تجارت الکترونیک اعلام میدارم کلیه کالاهای خریداری شده از شرکت سفیران یگانه را مصرف نموده و یا به فروش رسانده ام و تایید این متن به منزله این است که هیج مانده موجودی کالایی تا این تاریخ ندارم',
            Methods4: 'کلیه محصولات به صورت سالم تحویل اینجانب ...........درمورخ ..................گردید',
        }
    },
    {
        date: '21:42:42 30-07-1403',
        name: 'محمدرضا بهرامی راد',
        RemittanceNumber: '39859',
        RemittanceImage: Serial,
        NationalCode: '1830567345',
        OrderStatus: 'در حال پردازش',
        PostalCode: '1998135891',
        TelephoneNumber: '989122770733',
        Address: 'تهران - تهران - تهران سعادت آباد کوچه شهید مجید نامی کوچه آریا پلاک یک واحد دو',
        RowNumber: 1,
        GoogsCode: 91601100,
        GoodsTitle: 'اسپری ضدبوی کفش',
        deliveryMethods: {
            deliveryMethodNames: {
                Name1: 'انبار',
                Name2: 'ارسال پستی / انبار مرکزی',
            },
            deliveryMethodCodes: {
                Codes1: {
                    Code1: 'خ',
                    Code2: 'ت',
                    Code3: 'م',
                },
                Codes2: {
                    Code1: '1',
                    Code2: '0',
                    Code3: '1',
                }
            }
        },
        Remaining: 1,
        Price: 1500000,
        TotalPrice: 1500000,
        FinalSummition: 1920864,
        PriceUnit: 'ریال',
        SumOfTotalOfCounts: 1,
        TotalWeight: 0,
        WeightUnit: 'گرم',
        GoodsDeliveryPrice: 600000,
        ValueAddedTax: 174624,
        RetailDiscount: 5760,
        DeliveryMethod: {
            Methods1: 'اینجانب',
            Methods2: 'در تاریخ',
            Methods3: 'با علم و اطلاع کامل از کلیه مقررات و قوانین بازاریابی شبکه ای و قوانین تجارت الکترونیک اعلام میدارم کلیه کالاهای خریداری شده از شرکت سفیران یگانه را مصرف نموده و یا به فروش رسانده ام و تایید این متن به منزله این است که هیج مانده موجودی کالایی تا این تاریخ ندارم',
            Methods4: 'کلیه محصولات به صورت سالم تحویل اینجانب ...........درمورخ ..................گردید',
        }
    }
]