import React from 'react';
import ProfileImage from '../../assets/icons/profileImage.png';
import { TreeIcon } from '../../data/Icon';
import { Level } from '../../data/Icon';
const Profiles = (props) => {
    const {
        C1,
        C2,
        C3,
        C4,
        List,
        Filter
    } = props
    
    return (
        <div className={C1}>
            <div className={C2}>
                <div className={C3}>
                    {
                        List.map((item, index) => {
                            return (
                                <div onClick={() => Filter(item.id)} key={index} className={C4}>
                                    <div className='w-full h-full flex justify-center items-center'>
                                        <div className='w-[235px] h-[235px] md:w-[285px] md:h-[285px] xl:w-[237px] xl:h-[237px] 2xl:w-[295px] 2xl:h-[295px] rounded-full bg-[#176b87e5]'>
                                            <div className='w-full flex justify-center mt-3'>
                                                <div className='w-[80px] h-[80px] md:w-[90px] md:h-[90px] xl:w-[60px] xl:h-[60px] 2xl:w-[100px] 2xl:h-[100px] rounded-full bg-[rgba(255,255,255,1)]'>
                                                    <img src={ProfileImage} className='object-cover p-[1px]' />
                                                </div>
                                            </div>
                                            <div className='w-full flex justify-center mt-2 xl:mt-3'>
                                                <div className='w-[140px] h-[20px] sm:h-[18px] xl:w-[140px] xl:h-[20px] 2xl:w-[180px] 2xl:h-[25px] bg-[rgba(255,255,255,1)] flex items-center justify-center text-[10px] md:text-[13px] xl:text-[12px] 2xl:text-[13px] rounded-t-full'>
                                                    <span className='translate-y-[2px]'>sib1401036349938</span>
                                                </div>
                                            </div>
                                            <div className='w-full flex px-1'>
                                                <div className='w-full flex-grow border-t-[2px] border-[rgba(255,255,255,0.7)]'>
                                                    <div className='w-full flex justify-between items-center mt-3'>
                                                        <div className='flex-1 flex justify-center px-8 items-center'>
                                                            <div className='text-[rgba(255,255,255,1)] text-[12px] md:text-[16px] xl:text-[13px] flex h-full justify-end'>
                                                                سطح
                                                            </div>
                                                            <div className='h-full flex items-center text-[12px] md:text-[16px] xl:text-[13px] px-[5px] text-[rgba(255,255,255,1)]'>
                                                                {item.Level}
                                                            </div>
                                                            <div className='w-[20px] h-[20px] sm:w-[22px] sm:h-[22px] md:w-[35px] md:h-[35px] xl:w-[20px] xl:h-[20px] 2xl:w-[25px] 2xl:h-[25px] p-[4px] md:p-[7px] xl:p-[3px] 2xl:p-[5px] relative flex justify-center items-center rounded-full bg-[rgba(255,255,255,1)]'>
                                                                <Level />
                                                            </div>
                                                        </div>
                                                        <div className='flex-1 flex justify-start items-center -translate-x-4'>
                                                            <div className='text-[rgba(255,255,255,1)] text-[12px] md:text-[16px] xl:text-[13px] flex h-full'>
                                                                جایگاه
                                                            </div>
                                                            <div className='h-full flex items-center text-[12px] md:text-[16px] xl:text-[13px] px-[5px] text-[rgba(255,255,255,1)]'>
                                                                {item.count}
                                                            </div>
                                                            <div className='w-[20px] h-[20px] sm:w-[22px] sm:h-[22px] md:w-[35px] md:h-[35px] xl:w-[20px] xl:h-[20px] 2xl:w-[25px] 2xl:h-[25px] p-[4px] md:p-[7px] xl:p-[3px] 2xl:p-[5px] relative flex justify-center items-center rounded-full bg-[rgba(255,255,255,1)]'>
                                                                <TreeIcon />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[calc(100%+50px)] -translate-x-6 flex justify-center'>
                                                <div className='w-full h-[25px] xl:h-[30px] rounded-full bg-[rgba(255,255,255,1)] mt-2 md:mt-3 xl:mt-3'>
                                                    <div className='w-full h-full flex justify-center items-center'>
                                                        <span className='px-3 text-[10px] sm:text-[12px] md:text-[14px]'>{item.PersonalPrice}</span>
                                                        <span className='text-[10px] sm:text-[12px] md:text-[14px]'>{`: (ریال) شخصی`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[calc(100%+50px)] -translate-x-6 flex justify-center'>
                                                <div className='w-full h-[25px] xl:h-[30px] rounded-full bg-[rgba(255,255,255,1)] mt-2 md:mt-3'>
                                                    <div className='w-full h-full flex justify-center items-center'>
                                                        <span className='px-3 text-[10px] sm:text-[12px] md:text-[14px]'>{item.TeamPrice}</span>
                                                        <span className='text-[10px] sm:text-[12px] md:text-[14px]'>{`: (ریال) تیمی`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full absolute -bottom-[85px] flex justify-center items-center'>
                                        <div className='w-full'>
                                            <div className='w-full px-[30px]'>
                                                <div className='w-full text-[rgba(255,255,255,1)] h-[35px] flex justify-center items-center rounded-full bg-[#176b87]'>
                                                    {item.positionName}
                                                </div>
                                            </div>
                                            <div className='w-full px-[30px] mt-1'>
                                                <div className='w-full text-[rgba(255,255,255,1)] h-[35px] flex justify-center items-center rounded-full bg-[#176b87]'>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Profiles
