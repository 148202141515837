import React from 'react';
import FadeLoader from 'react-spinners/FadeLoader';

const LoadingComponent = ({
    Text
    , color
    , height
    , width
    , radius
    , margin
    , speedMultiplier
    , classes
}) => {
    return (
        <section className={classes.c1}>
            <FadeLoader
                color={color}
                height={height} // Adjust the height as needed
                width={width}  // Adjust the width as needed
                radius={radius} // Optional: radius of each spinner line
                margin={margin} // Optional: margin between spinner lines
                speedMultiplier={speedMultiplier}
            />
            {Text && (
                <p className={classes.c2}>{Text}</p>
            )}
        </section>
    );
};

export default LoadingComponent;
