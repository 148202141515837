import React from 'react'
import WarningImg from '../../assets/icons/warrning.png'
const SystemMessages = ({
    SysMsg
}) => {
    return (
        <section className='w-full mt-20 lg:mt-0 px-5'>
            <div className='w-full flex border-b-[1px] border-[rgba(0,0,0,0.2)] py-5 justify-end items-center'>
                <span className='text-sm sm:text-sm md:text-md lg:text-lg mx-4'>پیام های سیستمی</span>
                <span><img src={WarningImg} alt='WarningImg' className='w-[50px]' /></span>
            </div>
            <div className='w-full'>
                <ul className='w-full'>
                    {
                        SysMsg.map((item, index) => (
                            <li key={index} className={`w-full py-5 text-[12px] sm:text-sm md:text-md lg:text-base  mt-2 text-end px-10 rounded-[10px] ${item.color}`}>
                                <a href='#'>{item.title}</a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    )
}

export default SystemMessages
