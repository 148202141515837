import React from 'react'
import Search from '../../assets/icons/Search.png'
const SearchComponent = (props) => {
    const {
        C1,
        C2,
    } = props
    return (
        <div className={C1}>
            <div className={C2}>
                <img src={Search} className={`w-[30px]`} />
            </div>
        </div>
    )
}

export default SearchComponent
