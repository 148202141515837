import React, { useEffect, useState } from 'react';
import useWindowWidth from './hooks/useWindowWidth.js';
import SubMenu from './components/SubMenu/SubMenu.jsx';
import LoadingComponent from './hooks/LoadingComponent.js';
import MenuComponet from './components/MenuComponent/MenuComponet.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TitleComponent from './components/TitleComponent/TitleComponent.jsx';
import ProfileImage from './components/ProfileImageComponent/ProfileImage.jsx';
import DashboardComponent from './components/DashboardComponent/DashboardComponent.jsx';
import MobileMenuComponent from './components/MobileMenuComponent/MobileMenuComponent.jsx';
import TreeShematicWithDetails from './components/TreeShematicWithDetails/TreeShematicWithDetails.jsx';
import LinearNetwork from './components/LinearNetwork/LinearNetwork.jsx';
import CommissionReceived from './components/CommissionReceived/CommissionReceived.jsx';
import LatestSellingReport from './components/LatestSellingReport/LatestSellingReport.jsx';
import TeamActivitist from './components/TeamActivitist/TeamActivitist.jsx';
import PeopleWhoDontHavePurchase from './components/PeopleWhoDontHavePurchase/PeopleWhoDontHavePurchase.jsx';
import GoodsDeliveryRemittance from './components/GoodsDeliveryRemittance/GoodsDeliveryRemittance.jsx';
import GoodsOrder from './components/GoodsOrder/GoodsOrder.jsx';
import VistaComponent from './components/VistaComponent/VistaComponent.jsx';
import SibYar from './components/SibYar/SibYar.jsx';
import Close from './assets/icons/close.png';
import { Profile } from './data/Images.js';
import { Lists } from './data/Menu.js';
const App = () => {
  const width = useWindowWidth();
  const [preloader, setPreloader] = useState(true);
  const [SubMenuLoader, setSubMenuLoader] = useState([])
  const [SubMenuLoaderId, setSubMenuLoaderId] = useState(0)
  const [HamburgerMenu, setHamburgerMenu] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  // Set preloader to false when width is defined
  const SubMenuLoaderFunc = (data, id) => {
    setSubMenuLoader(data)
    setSubMenuLoaderId(id)
  }
  useEffect(() => {
    if (width) setPreloader(false);
    if (width < 1024) {
      setHamburgerMenu(true)
      setOpenMobileMenu(false)
    } else {
      setHamburgerMenu(false)
      setOpenMobileMenu(true)
    }
  }, [width]);

  const MobileMenuFunc = (data) => {
    if (HamburgerMenu && data) {
      setOpenMobileMenu(data)
    } else {
      setOpenMobileMenu(false)
    }

  }
  // Memoizing the LoadingComponent to prevent unnecessary re-renders
  const Preloader = React.memo(() => (
    <LoadingComponent
      color={'#ffffff'}
      height={15}
      width={2}
      radius={2}
      margin={2}
      speedMultiplier={1}
      classes={{
        c1: `w-full min-h-screen flex flex-col justify-center items-center bg-[#176B87]`,
        c2: `text-white mt-[30px] text-lg`
      }}
      Text={' . . . در حال اماده سازی , لطفا شکیبا باشید'}
    />
  ));
  const onOutsideClick = () => {
    setSubMenuLoader([])
  }
  const HideAction = (data) => {
    setOpenMobileMenu(data)
  }
  const Loading = (data) => {
    console.log(data)
    setPreloader(data)
  }
  return (
    <main className="w-full h-screen overflow-x-hidden -scrollbar-hidden">
      {preloader ? (
        <Preloader />
      ) : (
        <>
          {
            openMobileMenu &&
            <div className="w-full -scrollbar-hidden md:w-[350px] h-screen overflow-y-auto z-40 absolute top-0 right-0 bg-[rgba(23,32,42,1)]">
              <ProfileImage
                C1={`w-full h-[200px] flex justify-center items-center`}
                C2={`w-[150px] h-[150px] p-1 rounded-full bg-slate-50`}
                ImageClass={``}
                ImageSource={Profile}
                Alt={`Safiran Profile Image`}

              />
              <TitleComponent
                Fname={`فرشاد`}
                Lname={`نبیزاده`}
                C1={`w-full flex justify-center`}
                C2={`text-xl text-white mx-2`}
                C3={`text-xl text-white`}
                UserID={`w-full text-center text-white mt-4`}
                Warning={`w-full text-center text-white mt-2`}
                Time={`w-full text-center text-white mt-2`}
                Identification={`vt65051923892`}
                WarningText={`زمان باقی مانده تا پایان دوره`}
                TimeText={`28 روز 10 ساعت 34 دقیقه 14 ثانیه`}
              />
              <MenuComponet
                Lists={Lists}
                C1={`w-full mt-[20px]`}
                C2={`w-full p-1 mt-[5px]`}
                C3={`w-full relative py-3 bg-[rgba(255,255,255,0.9)] hover:bg-[#176B87] hover:text-[#ffffff] mt-1 flex cursor-pointer justify-end items-center`}
                C4={`-translate-x-1`}
                C5={``}
                C6={`h-full absolute z-0 top-0 left-5 flex items-center`}
                SubMenuLoaderFunc={SubMenuLoaderFunc}
              />
              {
                width < 1024 &&
                <div className='w-full absolute top-0'>
                  <div onClick={() => HideAction(false)} className='w-full relative'>
                    <img src={Close} className='w-[20px] translate-x-[10px] translate-y-[20px] absolute top-0 left-0' />
                  </div>
                </div>
              }
            </div>
          }
          {
            SubMenuLoader.length > 0 && openMobileMenu &&
            <div className='w-[600px] absolute z-30 top-0 right-0 h-screen'>
              <SubMenu
                SubMenuList={SubMenuLoader}
                C1={`w-full flex items-center`}
                C2={`w-full ${SubMenuLoaderId == 2 ? 'translate-y-[395px]' :
                  SubMenuLoaderId == 3 ? 'translate-y-[445px]' :
                    SubMenuLoaderId == 5 ? 'translate-y-[550px]' :
                      SubMenuLoaderId == 6 ? 'translate-y-[600px]' : ''}`}
                C3={`w-[50%] cursor-pointer bg-[rgba(23,32,42,1)] hover:bg-[#176B87] hover:text-[#ffffff] mt-[1px] py-5 borde flex justify-end items-center px-5`}
                C4={`text-white`}
                C5={`text-white mx-3`}
                onOutsideClick={onOutsideClick}
              />
            </div>
          }
          {
            HamburgerMenu &&
            <MobileMenuComponent
              MobileMenuFunc={MobileMenuFunc}
              openMobileMenu={openMobileMenu}
              Hidden={width}
              C1={`w-full h-[60px] z-30 absolute top-0 bg-[rgba(23,32,42,1)]`}
              C2={`w-full h-full flex justify-between`}
              C3={`h-full flex items-center px-3 sm:px-10`}
              C4={`w-[30px] sm:w-[30px] cursor-pointer`}
              C5={`h-full flex items-center px-8 sm:px-10 text-end`}
              C6={`text-white mx-3 text-[10px] sm:text-sm md:text-md lg:text-lg xl:text-xl `}
              C7={`w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] rounded-full bg-white p-[1px]`}
            />
          }

          <Router>
            <Routes>
              <Route path="/" element={<DashboardComponent Loading={Loading} />} />
              <Route path="/shematic/tree" element={<TreeShematicWithDetails Loading={Loading} width={width} />} />
              <Route path="/shematic/linear" element={<LinearNetwork Loading={Loading} width={width} />} />
              <Route path="/commission/received" element={<CommissionReceived Loading={Loading} width={width} />} />
              <Route path="/commission/latest" element={<LatestSellingReport Loading={Loading} width={width} />} />
              <Route path="/team/activitist" element={<TeamActivitist Loading={Loading} width={width} />} />
              <Route path="/team/peoplewhodonthavepurchase" element={<PeopleWhoDontHavePurchase Loading={Loading} width={width} />} />
              <Route path="/team/goodsdeliveryremittance" element={<GoodsDeliveryRemittance Loading={Loading} width={width} />} />
              <Route path="/team/goods/order" element={<GoodsOrder Loading={Loading} width={width} />} />
              <Route path="/vista" element={<VistaComponent Loading={Loading} width={width} />} />
              <Route path="/sibyar" element={<SibYar Loading={Loading} width={width} />} />
              <Route path="*" element={<h2>404 - Page Not Found</h2>} />
            </Routes>
          </Router>
        </>
      )}
    </main>
  );
};

export default App;
