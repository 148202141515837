import React, { useEffect, useState } from 'react'
const ResponsiveComponent = ({ setLoadingStatus, transactions, RenderButtomComponents }) => {
    const [startPreloader, setStartPreloader] = useState(false)
    const direction = { 'direction': 'rtl' }
    const tableHeaders = [
        'شماره',
        'وضعیت',
        'مبلغ',
        'تنظیمات',
        'تاریخ',
    ]
    // useEffect(() => {
    //     setLoadingStatus(startPreloader)
    //     setTimeout(() => {
    //         setLoadingStatus(false)
    //     }, 1000)
    // }, [startPreloader])
    return (
        <div className='w-full h-full px-0 mt-[60px] lg:mt-0 2xl:mt-[10px] 2xl:px-3' style={{ direction: 'rtl' }}>
            <table className='w-full bg-[#2c5079] text-white 2xl:rounded-[5px]'>
                <thead>
                    <tr className='text-center'>
                        {tableHeaders.map((header, index) => (
                            <th
                                key={index}
                                className={`text-[13px] 2xl:text-base py-4 ${index === 0 ? 'px-2' : 'px-4'}`}
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((item, index) => (
                        <tr key={index} className='text-center border-b-[1px]'>
                            <td className='py-2 text-[13px] 2xl:text-base persian-number'>{item.number}</td>
                            <td className='py-2 text-[13px] 2xl:text-base'>{item.status}</td>
                            <td className='py-2 text-[13px] 2xl:text-base persian-number'>
                                {item.amount} <span className='mx-1'>{item.unit}</span>
                            </td>
                            <td className='py-2'>
                                <div className='inline-flex space-x-2 space-x-reverse'>
                                    <button className='w-[60px] 2xl:w-[100px] rounded-[10px] bg-[#833939] text-white text-[13px] 2xl:text-base py-2 border-[1px] border-[rgba(255,255,255,1)]' onClick={()=>RenderButtomComponents({ BTN: 'ViewButtom', Number: item.number })}>نمایش</button>
                                    <button className='w-[60px] 2xl:w-[100px] rounded-[10px] bg-[#5f46bb] text-white text-[13px] 2xl:text-base py-2 border-[1px] border-[rgba(255,255,255,1)]' onClick={()=>RenderButtomComponents({ BTN: 'CollectButtom', Number: item.number })}>تجمیع</button>
                                    <button className='w-[60px] 2xl:w-[100px] rounded-[10px] bg-[#5d3363] text-white text-[13px] 2xl:text-base py-2 border-[1px] border-[rgba(255,255,255,1)]' onClick={()=>RenderButtomComponents({ BTN: 'CodeDelivery', Number: item.number })}>کد تحویل</button>
                                </div>
                            </td>
                            <td className='py-2 text-[13px] 2xl:text-base persian-number'>{item.date}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ResponsiveComponent
