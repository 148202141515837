import React, { useState } from 'react'
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import transition from "react-element-popper/animations/transition"
const LatestSellingReport = (props) => {
    const direction = { direction: 'rtl' }
    const Title = [
        { name: 'نام کاربری' },
        { name: 'شماره جایگاه' },
        { name: 'خرید شخصی' },
        { name: 'خرید شخصی pv' },
        { name: 'خرید شخصی sv' },
        { name: 'خرید شاخه' },
        { name: 'خرید شاخه sv' },
        { name: 'خرید شاخه pv' },
        { name: 'سطح' },
    ]
    const [sample, setSample] = useState([
        {
            userame: 'daste3',
            countOfPlace: 4,
            personalPurchase: 30224296,
            pvPersonalPurchase: 200,
            svPersonalPurchase: 0,
            branchPurchase: 30224296,
            svBranchPurchase: 30224296,
            pvBranchPurchase: 30224296,
            level: false,
        },
        {
            userame: 'daste3',
            countOfPlace: 4,
            personalPurchase: 30224296,
            pvPersonalPurchase: 200,
            svPersonalPurchase: 0,
            branchPurchase: 30224296,
            svBranchPurchase: 30224296,
            pvBranchPurchase: 30224296,
            level: false,
        },
        {
            userame: 'daste3',
            countOfPlace: 4,
            personalPurchase: 30224296,
            pvPersonalPurchase: 200,
            svPersonalPurchase: 0,
            branchPurchase: 30224296,
            svBranchPurchase: 30224296,
            pvBranchPurchase: 30224296,
            level: true,
        },
        {
            userame: 'daste3',
            countOfPlace: 4,
            personalPurchase: 30224296,
            pvPersonalPurchase: 200,
            svPersonalPurchase: 0,
            branchPurchase: 30224296,
            svBranchPurchase: 30224296,
            pvBranchPurchase: 30224296,
            level: true,
        },
        {
            userame: 'daste3',
            countOfPlace: 4,
            personalPurchase: 30224296,
            pvPersonalPurchase: 200,
            svPersonalPurchase: 0,
            branchPurchase: 30224296,
            svBranchPurchase: 30224296,
            pvBranchPurchase: 30224296,
            level: true,
        },
        {
            userame: 'daste3',
            countOfPlace: 4,
            personalPurchase: 30224296,
            pvPersonalPurchase: 200,
            svPersonalPurchase: 0,
            branchPurchase: 30224296,
            svBranchPurchase: 30224296,
            pvBranchPurchase: 30224296,
            level: true,
        },
        {
            userame: 'daste3',
            countOfPlace: 4,
            personalPurchase: 30224296,
            pvPersonalPurchase: 200,
            svPersonalPurchase: 0,
            branchPurchase: 30224296,
            svBranchPurchase: 30224296,
            pvBranchPurchase: 30224296,
            level: true,
        }
    ])
    const [selectedStartDate, setSelectedStartDay] = useState({
        day: '',
        month: '',
        year: '',
    });
    const [selectedEndDate, setSelectedEndDay] = useState({
        day: '',
        month: '',
        year: '',
    });
    const { width, Loading } = props
    // useEffect(() => { Loading(false) }, [])
    const sETStartDay = (e) => {
        setSelectedStartDay((prev) => ({
            ...prev,
            day: e.day,
            month: e.month.number,
            year: e.year,
        }))
    }
    const sETEndDay = (e) => {
        setSelectedEndDay((prev) => ({
            ...prev,
            day: e.day,
            month: e.month.number,
            year: e.year,
        }))
    }
    return (
        <div className='w-full lg:w-[calc(100%-350px)] 2xl:w-[calc(100%-350px)] min-h-screen'>
            <div className='w-full'>
                <div className='w-full px-5 lg:px-0 mt-[80px] 2xl:px-20 lg:mt-6'>
                    <div className='w-full lg:px-[30px] 2xl:px-0'>
                        <div className='w-full h-[200px] sm:h-[60px]  2xl:h-[60px] rounded-md'>
                            <div className='w-full h-full grid grid-cols-1 sm:grid-cols-3  xl:grid-cols-3 gap-3'>
                                <div className='w-full h-full'>
                                    <fieldset style={direction} className='w-full h-full border-2 border-[#3764a0d2] rounded-xl'>
                                        <legend className='mx-4 px-4'>تاریخ پایان</legend>
                                        <div className='w-full h-full overflow-hidden'>
                                            <DatePicker
                                                inputClass="custom-input-datepicker"
                                                calendar={persian}
                                                locale={persian_fa}
                                                animations={[transition()]}
                                                calendarPosition="bottom-right"
                                                onChange={sETEndDay}
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                                <div className='w-full h-full'>
                                    <fieldset style={direction} className='w-full h-full overflow-hidden border-2 border-[#3764a0d2] rounded-xl'>
                                        <legend className='mx-4 px-4'>تاریخ شروع</legend>
                                        <div className='w-full h-full overflow-hidden'>
                                            <DatePicker
                                                inputClass="custom-input-datepicker"
                                                calendar={persian}
                                                locale={persian_fa}
                                                animations={[transition()]}
                                                calendarPosition="bottom-right"
                                                onChange={sETStartDay}
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                                <div className='w-full h-full flex items-end'>
                                    <button className='w-full text-white h-full sm:h-[85%] 2xl:h-[85%] text-lg flex rounded-xl justify-center items-center bg-gradient-to-tr from-[#496ebe] to-[#270468]'>
                                        جستجو
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-full mt-10 sm:px-5 md:px-[40px] lg:px-[30px] xl:px-[40px] 2xl:px-[70px]`}>
                {
                    width >= 800 &&
                    <div className='w-full'>
                        <ul style={direction} className='w-full CommissionListBoxShadow flex justify-between px-8 py-3 rounded-xl sm:text-[13px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px]'>
                            {Title.map((item, index) => (
                                <li key={index}>
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                        {
                            sample.map((item, index) => {
                                return (
                                    <ul key={index} style={direction} className={`w-full mt-5 CommissionListBoxShadow ${item.level ? 'bg-[#357a8f] text-white' : ''} sm:text-[12px] xl:text-[14px] 2xl:text-[16px] flex justify-between px-8 py-3 rounded-xl`}>
                                        <li>{item.userame.toUpperCase()}</li>
                                        <li className='persian-number'>{item.countOfPlace}</li>
                                        <li className='persian-number'>{item.personalPurchase.toLocaleString()}</li>
                                        <li className='persian-number'>{item.pvPersonalPurchase.toLocaleString()}</li>
                                        <li className='persian-number'>{item.svPersonalPurchase.toLocaleString()}</li>
                                        <li className='persian-number'>{item.branchPurchase.toLocaleString()}</li>
                                        <li className='persian-number'>{item.svBranchPurchase.toLocaleString()}</li>
                                        <li className='persian-number'>{item.pvBranchPurchase.toLocaleString()}</li>
                                        <li>{item.level ? 'سطح بعدی' : ''}</li>
                                    </ul>
                                )
                            })
                        }
                    </div>
                }
                {
                    width < 800 &&
                    <div className='w-full px-[20px] pb-[30px] sm:px-0 grid grid-cols-1 sm:grid-cols-2 gap-3'>
                        {
                            sample.map((item, index) => (
                                <div className={`w-full py-5 rounded-2xl ${item.level?'bg-[#2c575f] text-white':''} border-2 border-[#02363fa8]`}>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>نام کاربری :</span>
                                        <span className='mx-5'>{item.userame.toUpperCase()}</span>
                                    </div>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>شماره جایگاه :</span>
                                        <span className='mx-5'>{item.countOfPlace}</span>
                                    </div>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>خرید شخصی :</span>
                                        <span className='mx-5'>{item.personalPurchase.toLocaleString()}</span>
                                    </div>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>pv خرید شخصی :</span>
                                        <span className='mx-5'>{item.pvPersonalPurchase.toLocaleString()}</span>
                                    </div>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>sv خرید شخصی:</span>
                                        <span className='mx-5'>{item.svPersonalPurchase.toLocaleString()}</span>
                                    </div>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>خرید شاخه:</span>
                                        <span className='mx-5'>{item.branchPurchase.toLocaleString()}</span>
                                    </div>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>sv خرید شاخه:</span>
                                        <span className='mx-5'>{item.svBranchPurchase.toLocaleString()}</span>
                                    </div>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>pv خرید شاخه :</span>
                                        <span className='mx-5'>{item.pvBranchPurchase.toLocaleString()}</span>
                                    </div>
                                    <div style={direction} className='px-5 my-2'>
                                        <span>سطح:</span>
                                        <span className='mx-5'>{item.level ? 'سطح بعدی' : ''}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default LatestSellingReport
