import { useState, useEffect } from 'react';

/**
 * Custom Hook: useWindowWidth
 * This hook tracks the window's current width and updates the value dynamically when the window is resized.
 *
 * @returns {number} width - The current width of the window in pixels.
 */
const useWindowWidth = () => {
    // Initialize state with the current window width.
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update the width state when the window is resized.
        const handleResize = () => setWidth(window.innerWidth);

        // Add event listener for the 'resize' event.
        window.addEventListener('resize', handleResize);

        // Cleanup: Remove event listener when the component using the hook unmounts.
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array ensures the effect runs only once, when the component mounts.

    // Return the current window width.
    return width;
};

export default useWindowWidth;
