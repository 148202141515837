import React, { useEffect, useState } from 'react';
import HamburgerMenu from '../../assets/icons/HamburgerMenu.png';
import Logo from '../../assets/icons/logo.svg';
import Close from '../../assets/icons/close.png';
const MobileMenuComponent = ({
    MobileMenuFunc,
    openMobileMenu,
    Size,
    C1,
    C2,
    C3,
    C4,
    C5,
    C6,
    C7,
}) => {
    const [CloseStatus, setCloseStatus] = useState(false)
    const ModalAction = (data) => {
        MobileMenuFunc(data)
        setCloseStatus(data)
    }
    useEffect(() => {

        setCloseStatus(openMobileMenu)
        console.log('open mobile menu', openMobileMenu)
    }, [openMobileMenu])
    return (
        <section className={C1}>
            <div className={C2}>
                <div className={C5}>
                    <img src={Logo} alt='Safiran-e-yeganeh' className={C7} />
                    <span className={C6}>شرکت سفیران یگانه بازاریابی اسیا</span>
                </div>
                <div className={C3}>
                    <img onClick={() => ModalAction(!openMobileMenu)} src={!CloseStatus ? HamburgerMenu : Close} alt='Safiran Menu' className={C4} />
                </div>
            </div>
        </section>
    )
}

export default MobileMenuComponent
