export const employees = [
  {
    id: 1,
    parentId: "",
    name: "جان",
    positionName: "مدیر عامل",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.",
    imageUrl: "https://randomuser.me/api/portraits/men/1.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 2,
    parentId: "1",
    name: "اسمیت",
    positionName: "مدیر عملیات",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 3,
    parentId: "1",
    name: "کیت",
    positionName: "مدیر فناوری",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/women/3.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 4,
    parentId: "6",
    team: "تیم منابع انسانی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 5,
    parentId: "3",
    name: "اریکا",
    positionName: "مدیر بخش",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/women/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 6,
    parentId: "3",
    name: "پاول",
    positionName: "مدیر بخش",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/6.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 7,
    parentId: "5",
    team: "توسعه‌دهندگان",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 8,
    parentId: "3",
    name: "تونی",
    positionName: "مدیر بخش",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 9,
    parentId: "2",
    name: "سالی",
    positionName: "مدیر بخش",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/women/9.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 10,
    parentId: "4",
    name: "اسکات",
    positionName: "دستیار منابع انسانی",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/10.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 11,
    parentId: "1",
    name: "جیمز",
    positionName: "مدیر رشد",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/7.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 12,
    parentId: "4",
    name: "تونی",
    positionName: "دستیار منابع انسانی",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 13,
    parentId: "4",
    name: "سالی",
    positionName: "دستیار منابع انسانی",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/women/9.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 14,
    parentId: "8",
    name: "اسکات",
    positionName: "معلم",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/10.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 15,
    parentId: "8",
    name: "جیمز",
    positionName: "معلم",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/7.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 16,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 17,
    parentId: "7",
    name: "سالی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/women/9.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 18,
    parentId: "8",
    name: "اسکات",
    positionName: "معلم",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/10.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 19,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 20,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  }, {
    id: 21,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 22,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 23,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 24,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 25,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 26,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  },
  {
    id: 27,
    parentId: "7",
    name: "تونی",
    positionName: "توسعه‌دهنده",
    phone: "۹۹۸۸۷۷۶۶",
    email: "employee@email.com",
    team: "",
    location: "شعبه لس آنجلس",
    department: "بازاریابی",
    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ است.",
    imageUrl: "https://randomuser.me/api/portraits/men/8.jpg",
    PersonalPrice: "۰",
    TeamPrice: "۴۷۵,۰۰۰",
    Level: "۱۸",
    count: "۳۸۷"
  }
];

export default employees;
