import React, { useEffect, useState } from 'react'
import { VistaWidgets } from '../../data/VistaWigets'
const VistaComponent = (props) => {
    const { width, Loading } = props
    const [styles, setStyles] = useState({
        Class1: '',
        Class2: '',
        Class3: '',
        Class4: '',
    })
    const LoadingActivatorFunction = () => {
        Loading(true)
        setTimeout(() => {
            Loading(false)
        }, 500)
    }
    useEffect(() => {
        if (width <= 640) {
            setStyles((prev) => ({
                ...prev,
                Class1: 'w-full px-5',
                Class2: 'w-full justify-between mt-6 px-5 py-3 text-white rounded-xl bg-[rgba(32,109,158)] flex items-center CommissionListBoxShadow',
                Class3: 'w-[50px] h-[50px] p-2 rounded-full bg-white flex justify-center items-center',
                Class4: 'font-[500]',
            }))
        }
        if (width > 640 && width <= 800) {
            setStyles((prev) => ({
                ...prev,
                Class1: 'w-full grid grid-cols-2 gap-4 px-5 mt-[0px]',
                Class2: 'w-full justify-between mt-6 px-5 py-3 text-white rounded-xl bg-[rgba(32,109,158)] flex items-center CommissionListBoxShadow',
                Class3: 'w-[50px] h-[50px] p-2 rounded-full bg-white flex justify-center items-center',
                Class4: 'font-[500]',
            }))
        }
        if (width > 800 && width < 1024) {
            setStyles((prev) => ({
                ...prev,
                Class1: 'w-full grid grid-cols-2 gap-4 px-5 mt-[0px]',
                Class2: 'w-full justify-between mt-6 px-5 py-3 text-white rounded-xl bg-[rgba(32,109,158)] flex items-center CommissionListBoxShadow',
                Class3: 'w-[50px] h-[50px] p-2 rounded-full bg-white flex justify-center items-center',
                Class4: 'font-[500]',
            }))
        }
        if (width > 1024 && width <= 1366) {
            setStyles((prev) => ({
                ...prev,
                Class1: 'w-full grid grid-cols-2 gap-4 px-5 mt-[0px]',
                Class2: 'w-full justify-between mt-6 px-5 py-3 text-white rounded-xl bg-[rgba(32,109,158)] flex items-center CommissionListBoxShadow',
                Class3: 'w-[50px] h-[50px] p-2 rounded-full bg-white flex justify-center items-center',
                Class4: 'font-[500]',
            }))
        }
        if (width > 1366 && width <= 1500) {
            setStyles((prev) => ({
                ...prev,
                Class1: 'w-full grid grid-cols-3 gap-4 px-5 mt-[0px]',
                Class2: 'w-full justify-between mt-6 px-5 py-3 text-white rounded-xl bg-[rgba(32,109,158)] flex items-center CommissionListBoxShadow',
                Class3: 'w-[50px] h-[50px] p-2 rounded-full bg-white flex justify-center items-center',
                Class4: 'font-[500]',
            }))
        }
        if (width > 1500 && width <= 1800) {
            setStyles((prev) => ({
                ...prev,
                Class1: 'w-full grid grid-cols-4 gap-4 px-5 mt-[0px]',
                Class2: 'w-full justify-between mt-6 px-5 py-3 text-white rounded-xl bg-[rgba(32,109,158)] flex items-center CommissionListBoxShadow',
                Class3: 'w-[50px] h-[50px] p-2 rounded-full bg-white flex justify-center items-center',
                Class4: 'font-[500]',
            }))
        }
        if (width > 1800 && width <= 2000) {
            setStyles((prev) => ({
                ...prev,
                Class1: 'w-full grid grid-cols-5 gap-4 px-5 mt-[0px]',
                Class2: 'w-full justify-between mt-6 px-5 py-3 text-white rounded-xl bg-[rgba(32,109,158)] flex items-center CommissionListBoxShadow',
                Class3: 'w-[50px] h-[50px] p-2 rounded-full bg-white flex justify-center items-center',
                Class4: 'font-[500]',
            }))
        }
        if (width > 2000) {
            setStyles((prev) => ({
                ...prev,
                Class1: 'w-full grid grid-cols-6 gap-4 px-5 mt-[0px]',
                Class2: 'w-full justify-between mt-6 px-5 py-3 text-white rounded-xl bg-[rgba(32,109,158)] flex items-center CommissionListBoxShadow',
                Class3: 'w-[50px] h-[50px] p-2 rounded-full bg-white flex justify-center items-center',
                Class4: 'font-[500]',
            }))
        }
    }, [width])

    const WidgetComponent = (props) => (
        <div style={{ direction: 'rtl' }} className={props.Class1}>
            {
                VistaWidgets.map((widget, index) => (
                    <div style={{ direction: 'ltr' }} key={index} className={props.Class2}>
                        <div className={props.Class3}>
                            {widget.img}
                        </div>
                        <div className={props.Class4}>{widget.position}</div>
                    </div>
                ))
            }
        </div>
    )
    return (
        <div className={`w-full flex  justify-center min-h-screen py-5 lg:w-[calc(100vw-350px)] xl:w-[calc(100vw-350px)]`}>
            <div className={`w-full min-h-screen relative`}>
                <div className='w-full flex items-center mt-[40px] lg:mt-[0] lg:px-5'>
                    <div className='w-full h-full flex text-white justify-center px-5 items-center leading-[30px] lg:py-5 py-2 bg-[#225736] lg:rounded-xl text-sm lg:text-lg text-center'>
                        سیستم آموزشی ویستا توسط گروه آموزشی شرکت سفیران یگانه بازاریابی آسیا به سرپرستی آقای مهدی پناهعلی پور گردآوری و تدوین شده است
                    </div>
                </div>
                <WidgetComponent
                    Class1={styles.Class1}
                    Class2={styles.Class2}
                    Class3={styles.Class3}
                    Class4={styles.Class4}
                />
            </div>
        </div>
    )
}

export default VistaComponent
