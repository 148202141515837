import React, { useState } from 'react'
import SystemMessagesComponent from '../SystemMessages/SystemMessages'
import DashboardWidgets from '../DashboardWidgets/DashboardWidgets'
const DashboardComponent = ({
    Loading
}) => {
    const [SystemMessages, setSystemMessages] = useState([
        {
            title: 'حساب کاربری شما احراز هویت نشده است',
            color: 'bg-[rgba(169,0,0,0.8)] text-white'
        },
        {
            title: 'شماره شبا شما ثبت نشده است',
            color: 'bg-[rgba(169,0,0,0.8)] text-white'
        }
    ])
    const LoadingStarter = () => {
        Loading(true)
    }
    return (
        <div className='w-full h-screen -scrollbar-hidden overflow-y-auto lg:w-[calc(100vw-350px)]  bg-[#ffffff]'>
            <div className='w-full flex justify-center'>
                <div className='container'>
                    <SystemMessagesComponent SysMsg={SystemMessages} />
                    <DashboardWidgets />
                </div>
            </div>
        </div>
    )
}

export default DashboardComponent
