export const Collections = [
    {
        code: 16441,
        collectiveRemittance: 39878,
        status: 'با این کد خرید انجام شده',
        username: 'sib61571894331',
        fullname: 'دانیال صفره',
        date: '1403-07-30 21:54:54',
    },
    {
        code: 16439,
        collectiveRemittance: 39875,
        status: 'با این کد خرید انجام شده',
        username: 'sib1401020249236',
        fullname: 'طاهره ابوطالبی',
        date: '1403-07-30 21:50:00',
    },
    {
        code: 16438,
        collectiveRemittance: 39871,
        status: 'با این کد خرید انجام شده',
        username: 'sib1401020298872',
        fullname: 'محمدسعید صوفی',
        date: '1403-07-30 21:46:37',
    },
    {
        code: 16434,
        collectiveRemittance: 39867,
        status: 'با این کد خرید انجام شده',
        username: 'sib1401020263546',
        fullname: 'شیوا بهرامی راد',
        date: '1403-07-30 21:43:39',
    },
]