export const CommissionReceivedList = [
    {
        Month: 'شهریور',
        Year: '۱۴۰۳',
        Detail: [
            {
                Price: '۳۳۶۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت رتبه ها',
                From: '۱۴۰۳-۰۶-۰۱',
                End: '۱۴۰۳-۰۶-۳۱',
                Status:true,
            },
            {
                Price: '۲۰۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت معرفی',
                From: '۱۴۰۳-۰۷-۱۵',
                End: '۱۴۰۳-۰۷-۲۰',
                Status:false,
            },
        ]
    },
    {
        Month: 'مهر',
        Year: '۱۴۰۳',
        Detail: [
            {
                Price: '۴۵۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت فروش',
                From: '۱۴۰۳-۰۷-۰۱',
                End: '۱۴۰۳-۰۷-۳۱',
                Status:true,
            },
            {
                Price: '۲۰۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت معرفی',
                From: '۱۴۰۳-۰۷-۱۵',
                End: '۱۴۰۳-۰۷-۲۰',
                Status:true,
            },
        ]
    },
    {
        Month: 'آبان',
        Year: '۱۴۰۳',
        Detail: [
            {
                Price: '۶۰۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت پروژه‌ها',
                From: '۱۴۰۳-۰۸-۰۱',
                End: '۱۴۰۳-۰۸-۳۱',
                Status:true,
            },
        ]
    },
    {
        Month: 'آذر',
        Year: '۱۴۰۳',
        Detail: [
            {
                Price: '۵۰۰۰۰۰۰',
                Unit: 'دلار',
                Plan: 'پورسانت بین‌المللی',
                From: '۱۴۰۳-۰۹-۰۱',
                End: '۱۴۰۳-۰۹-۱۵',
                Status:true,
            },
            {
                Price: '۳۰۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت داخلی',
                From: '۱۴۰۳-۰۹-۱۶',
                End: '۱۴۰۳-۰۹-۳۰',
                Status:true,
            },
        ]
    },
    {
        Month: 'شهریور',
        Year: '۱۴۰۳',
        Detail: [
            {
                Price: '۳۳۶۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت رتبه ها',
                From: '۱۴۰۳-۰۶-۰۱',
                End: '۱۴۰۳-۰۶-۳۱',
                Status:true,
            },
            {
                Price: '۲۰۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت معرفی',
                From: '۱۴۰۳-۰۷-۱۵',
                End: '۱۴۰۳-۰۷-۲۰',
                Status:true,
            },
        ]
    },
    {
        Month: 'مهر',
        Year: '۱۴۰۳',
        Detail: [
            {
                Price: '۴۵۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت فروش',
                From: '۱۴۰۳-۰۷-۰۱',
                End: '۱۴۰۳-۰۷-۳۱',
                Status:true,
            },
            {
                Price: '۲۰۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت معرفی',
                From: '۱۴۰۳-۰۷-۱۵',
                End: '۱۴۰۳-۰۷-۲۰',
                Status:true,
            },
        ]
    },
    {
        Month: 'آبان',
        Year: '۱۴۰۳',
        Detail: [
            {
                Price: '۶۰۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت پروژه‌ها',
                From: '۱۴۰۳-۰۸-۰۱',
                End: '۱۴۰۳-۰۸-۳۱',
                Status:true,
            },
        ]
    },
    {
        Month: 'آذر',
        Year: '۱۴۰۳',
        Detail: [
            {
                Price: '۵۰۰۰۰۰۰',
                Unit: 'دلار',
                Plan: 'پورسانت بین‌المللی',
                From: '۱۴۰۳-۰۹-۰۱',
                End: '۱۴۰۳-۰۹-۱۵',
                Status:true,
            },
            {
                Price: '۳۰۰۰۰۰۰',
                Unit: 'ریال',
                Plan: 'پورسانت داخلی',
                From: '۱۴۰۳-۰۹-۱۶',
                End: '۱۴۰۳-۰۹-۳۰',
                Status:true,
            },
        ]
    }
]