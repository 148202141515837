import React, { useEffect, useState } from 'react'
import SearchComponent from '../SearchComponent/SearchComponent'
import { employees } from "../../data/Schematic";
import Profiles from '../Profiles/Profiles';
const LinearNetwork = (props) => {
    const LoadingActivator = (data) => {
        props.Loading(data)
    }

    const [filteredEmployees, setFilteredEmployees] = useState(employees);
    const [searchTerm, setSearchTerm] = useState(''); // Store search term for better clarity
    const [PreviousId, setPreviousId] = useState([])
    useEffect(() => {
        const handleSearch = () => {
            if (!searchTerm) {
                setFilteredEmployees(employees); // Reset to original data
                return;
            }

            // Consider using a search library for more advanced filtering logic
            const filteredData = employees.filter((employee) => {
                const nameLower = employee.name?.toLowerCase() || ''; // Handle potential null values
                const positionLower = employee.positionName?.toLowerCase() || '';
                const levelLower = employee.Level?.toString().toLowerCase() || ''; // Handle Level as string

                return (
                    nameLower.includes(searchTerm.toLowerCase()) ||
                    positionLower.includes(searchTerm.toLowerCase()) ||
                    levelLower.includes(searchTerm.toLowerCase())
                );
            });

            setFilteredEmployees(filteredData);
        };

        handleSearch(); // Call on initial render and after searchTerm changes
    }, [searchTerm, employees]); // Include dependencies

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value); // Update search term on input change
    };
    const Filterazation = (data) => {
        const filteredData = employees.filter((employee) => {
            return parseInt(employee.parentId) === parseInt(data); // Use strict equality
        });
        if (filteredData != 0) {
            setFilteredEmployees(filteredData); // Use !== for comparison
            setPreviousId((prevIds) => [...prevIds, data]); // Update state immutably 
        }
    }

    console.log(PreviousId)
    // useEffect(() => {
    //     filteredEmployees.length > 0 ? LoadingActivator(0) : LoadingActivator(1)
    // }, [filteredEmployees])

    return (
        <div className='w-full relative lg:w-[calc(100vw-350px)] flex  justify-center h-screen overflow-hidden'>
            <div className={'container relative'}>
                <div className={`w-full px-[20px] lg:px-[100px] xl:px-[150px] 2xl:px-[300px] 2xl:-translate-x-5 py-[10px] flex items-center`}>
                    <div className={`w-full flex rounded-[100px] h-[50px]  bg-[#176b87e3] mt-[60px] lg:mt-0`}>
                        <div className={`h-full w-full flex items-center`}>
                            <div className={`w-full h-full flex rounded-l-[100px]`}>
                                <input
                                    className={`w-full h-full focus:outline-none text-white text-sm sm:text-[16px] placeholder:text-white bg-transparent flex items-center px-1 xl:px-10`}
                                    placeholder={`جستجو`}
                                    style={{ 'direction': 'rtl' }}
                                    type={`search`}
                                    onChange={handleSearchChange}
                                    value={searchTerm} // Set input value from searchTerm
                                />
                            </div>
                        </div>
                        <SearchComponent
                            C1={`h-full flex items-center`}
                            C2={`w-[50px] h-[50px] border-2 border-[#176b87e3] bg-[rgba(255,255,255,1)] rounded-full translate-x-1 flex justify-center items-center`}
                        />
                    </div>
                </div>
                <Profiles
                    C1={`w-full h-screen flex justify-center overflow-y-scroll -scrollbar-hidden lg:pb-[100px] lg:pt-[60px]`}
                    C2={`h-full overflow-y-scroll card xl:container overflow-x-hidden`}
                    C3={`w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-x-[20px] xl:gap-x-[0px] gap-y-[140px] mb-[100px] sm:mb-0 pt-[10px] pb-[100px] xl:translate-x-[30px] 2xl:translate-x-[60px]`}
                    C4={`h-[250px] w-[250px] md:w-[300px] md:h-[300px] xl:w-[250px]  xl:h-[250px]  2xl:w-[310px]  2xl:h-[310px] rounded-full border-[4px] border-[#176b87e3] relative cursor-pointer`}
                    List={filteredEmployees}
                    Filter={Filterazation}
                />
            </div>
            {
                PreviousId.length > 0 && <div className='w-[40px] h-full flex items-center absolute bottom-0 left-0'>
                    <div
                        onClick={() => {
                            if (PreviousId.length > 0) {
                                const updatedIds = [...PreviousId]; // Create a shallow copy of the array
                                updatedIds.pop(); // Remove the last item
                                setPreviousId(updatedIds); // Update the state

                                // Re-filter employees based on the previous parentId
                                const lastId = updatedIds.length > 0 ? updatedIds[updatedIds.length - 1] : null;
                                const filteredData = lastId
                                    ? employees.filter((employee) => parseInt(employee.parentId) === parseInt(lastId))
                                    : employees;

                                setFilteredEmployees(filteredData.length !== 0 ? filteredData : employees);
                            }
                        }}
                        className='h-[200px] translate-y-[80px] flex items-center justify-center rounded-tr-[100%] rounded-br-[100%] bg-[#176b87e3]'
                    >
                        <span className='text-[rgba(255,255,255,1)] -translate-x-1  cursor-pointer -rotate-90 text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl'>
                            قبلی
                        </span>
                    </div>

                </div>
            }
        </div>
    )
}

export default LinearNetwork
