export const transactions = [
    {
        number: 35899,
        status: "پرداخت شده", // Paid
        amount: 1963664, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-07-20 12:42:42",
    },
    {
        number: 38164,
        status: "پرداخت شده", // Paid
        amount: 1377460, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-06-31 10:58:18",
    },
    {
        number: 38587,
        status: "پرداخت شده", // Paid
        amount: 1377460, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-05-30 18:54:14",
    },
    {
        number: 32712,
        status: "پرداخت شده", // Paid
        amount: 1517640, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-04-30 15:16:29",
    },
    {
        number: 31451,
        status: "پرداخت شده", // Paid
        amount: 464950, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-04-31 09:09:08",
    },
    {
        number: 33938,
        status: "پرداخت شده", // Paid
        amount: 1517640, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-05-31 09:59:57",
    },
    {
        number: 35882,
        status: "پرداخت شده", // Paid
        amount: 1963644, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-07-31 14:00:45",
    },
    {
        number: 30830,
        status: "پرداخت شده", // Paid
        amount: 1451940, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-06-01 12:55:35",
    },
    {
        number: 25917,
        status: "پرداخت شده", // Paid
        amount: 1383431, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-07-31 13:33:26",
    },
    {
        number: 27813,
        status: "پرداخت شده", // Paid
        amount: 1585162, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-08-01 13:43:57",
    },
    {
        number: 32560,
        status: "پرداخت شده", // Paid
        amount: 1476714, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-10-12 12:56:05",
    },
    {
        number: 31971,
        status: "پرداخت شده", // Paid
        amount: 1648989, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-11-12 09:47:32",
    },
    {
        number: 23455,
        status: "پرداخت شده", // Paid
        amount: 1517640, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-05-01 13:40:09",
    },
    {
        number: 23975,
        status: "پرداخت شده", // Paid
        amount: 1476714, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-04-30 14:02:58",
    },
    {
        number: 20534,
        status: "پرداخت شده", // Paid
        amount: 1476714, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-06-01 12:55:34",
    },
    {
        number: 17660,
        status: "پرداخت شده", // Paid
        amount: 1477386, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-07-02 13:58:55",
    },
    {
        number: 17600,
        status: "پرداخت شده", // Paid
        amount: 1476714, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-04-31 12:34:45",
    },
    {
        number: 16259,
        status: "پرداخت شده", // Paid
        amount: 1476714, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-07-03 12:30:56",
    },
    {
        number: 14355,
        status: "پرداخت شده", // Paid
        amount: 1476714, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-08-01 12:56:57",
    },
    {
        number: 11981,
        status: "پرداخت شده", // Paid
        amount: 1475169, // Removed commas
        unit: "ریال", // Rial
        deliveryCode: "کد تحویل", // Delivery Code
        view: "نمایش", // View
        date: "1402-11-12 13:47:36",
    },
];
