import React, { useEffect, useState } from 'react'

const ResponsiveWithMobileComponent = ({ setLoadingStatus, transactions, RenderButtomComponents }) => {
    const [startPreloader, setStartPreloader] = useState(false)
    const direction = { 'direction': 'rtl' }
    const tableHeaders = ['شماره', 'وضعیت', 'مبلغ', 'تنظیمات', 'تاریخ']
    return (
        <div className="w-full h-full mt-[70px] px-4 md:px-20" style={{ direction: 'rtl' }}>
            <div className="w-full">
                {transactions.map((item, index) => (
                    <div
                        key={index}
                        className="grid grid-cols-2 gap-2 bg-[#f7fafc] rounded-md shadow-lg p-4 mb-4"
                    >
                        <div className="text-gray-600 persian-number font-semibold">شماره:</div>
                        <div className="text-gray-800 persian-number">{item.number}</div>

                        <div className="text-gray-600 font-semibold">وضعیت:</div>
                        <div className="text-gray-800">{item.status}</div>

                        <div className="text-gray-600 font-semibold">مبلغ:</div>
                        <div className="text-gray-800 persian-number">
                            {item.amount} <span className="mx-1">{item.unit}</span>
                        </div>

                        <div className="text-gray-600 font-semibold">تاریخ:</div>
                        <div className="text-gray-800 persian-number">{item.date}</div>

                        <div className="col-span-2 flex justify-around mt-4">
                            <button onClick={() => RenderButtomComponents({ BTN: 'ViewButtom', Number: item.number })} className="w-[80px] 2xl:w-[120px] rounded-[12px] bg-[#e63946] text-white text-[14px] 2xl:text-lg py-2">
                                نمایش
                            </button>
                            <button onClick={() => RenderButtomComponents({ BTN: 'CollectButtom', Number: item.number })} className="w-[80px] 2xl:w-[120px] rounded-[12px] bg-[#457b9d] text-white text-[14px] 2xl:text-lg py-2">
                                تجمیع
                            </button>
                            <button onClick={() => RenderButtomComponents({ BTN: 'CodeDelivery', Number: item.number })} className="w-[80px] 2xl:w-[120px] rounded-[12px] bg-[#6d597a] text-white text-[14px] 2xl:text-lg py-2">
                                کد تحویل
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ResponsiveWithMobileComponent
