import React, { useRef } from 'react'
import { Remittance } from '../../data/Remittances'
import Logo from '../../assets/icons/logo.svg'
import SignatureCanvas from 'react-signature-canvas';
import Return from '../../assets/icons/Return2.png';
const ResponsiveRemittance = (props) => {
    const { BackButton } = props
    const sigCanvasRefs = useRef(Remittance.map(() => React.createRef()));

    const clearSignature = (index) => {
        const signaturePad = sigCanvasRefs.current[index]?.current;
        if (signaturePad) {
            signaturePad.clear();
        }
    };
    return (
        <div className='w-full relative min-h-screen'>
            <div className='w-full mt-[60px] px-5 rounded-xl'>
                {
                    Remittance.map((item, index) => {
                        return (
                            <div className='w-full' key={index}>
                                <div className={`w-full relative ${index == 0 ? 'mt-[100px]' : ''} mb-[10px] py-3 rounded-[20px] border-[1px] border-[#4e4b4ba4]`}>
                                    <div className='w-full flex justify-center'>
                                        <img src={Logo} className='w-[70px]' />
                                    </div>
                                    <div className='w-full text-black py-3 flex items-center justify-between px-5'>
                                        <div>
                                            <div className='text-sm persian-number'>{item.RemittanceNumber}</div>
                                            <div><img src={item.RemittanceImage} className='w-[100px]' /></div>
                                        </div>
                                        <div className='text-sm persian-number'>{item.date}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div>{item.name}</div>
                                        <div>{`: نام خانوادگی`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number'>{item.NationalCode}</div>
                                        <div>{`: کد ملی`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number'>{item.OrderStatus}</div>
                                        <div>{`: وضعیت سفارش`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number'>{item.PostalCode}</div>
                                        <div>{`: کد پستی`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number'>{item.TelephoneNumber}</div>
                                        <div>{`: تلفن`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number text-[12px] text-end mr-3'>{item.Address}</div>
                                        <div className='text-[15px] whitespace-nowrap'>{`: ادرس کامل`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number'>{item.RowNumber}</div>
                                        <div>{`: ردیف`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number'>{item.GoogsCode}</div>
                                        <div>{`: کد کالا`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number'>{item.GoodsTitle}</div>
                                        <div>{`: عنوان کالا`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='w-full h-[100px] rounded-xl border-[1px] border-[#4e4b4ba4]'>
                                            <div className='w-full h-full grid grid-cols-2'>
                                                <div className='border-r-[1px] flex flex-col border-[#4e4b4ba4]'>
                                                    <div className='flex-1 flex justify-center border-b-[1px] border-[#4e4b4ba4]'>
                                                        <div className='w-full h-full flex justify-between'>
                                                            <div className='flex-1 flex justify-center items-center persian-number'>{item.deliveryMethods.deliveryMethodCodes.Codes1.Code3}</div>
                                                            <div className='flex-1 flex justify-center items-center border-l-[1px] border-[#4e4b4ba4] persian-number'>{item.deliveryMethods.deliveryMethodCodes.Codes1.Code2}</div>
                                                            <div className='flex-1 flex justify-center items-center border-l-[1px] border-[#4e4b4ba4] persian-number'>{item.deliveryMethods.deliveryMethodCodes.Codes1.Code1}</div>
                                                        </div>
                                                    </div>
                                                    <div className='flex-1 flex justify-center'>
                                                        <div className='flex-1 flex justify-center items-center persian-number'>{item.deliveryMethods.deliveryMethodCodes.Codes2.Code3}</div>
                                                        <div className='flex-1 flex justify-center items-center border-l-[1px] border-[#4e4b4ba4] persian-number'>{item.deliveryMethods.deliveryMethodCodes.Codes2.Code2}</div>
                                                        <div className='flex-1 flex justify-center items-center border-l-[1px] border-[#4e4b4ba4] persian-number'>{item.deliveryMethods.deliveryMethodCodes.Codes2.Code1}</div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-col border-[#4e4b4ba4]'>
                                                    <div className='flex-1 text-sm flex  justify-center items-center border-b-[1px] border-[#4e4b4ba4]'>
                                                        {item.deliveryMethods.deliveryMethodNames.Name1}
                                                    </div>
                                                    <div className='flex-1 text-sm flex justify-center items-center'>
                                                        {item.deliveryMethods.deliveryMethodNames.Name2}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div className='persian-number'>{item.Remaining}</div>
                                        <div>{`: مانده`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div style={{ direction: 'rtl' }} className='persian-number'>
                                            <span className='mx-1'>{item.Price}</span>
                                            <span>{item.PriceUnit}</span>
                                        </div>
                                        <div>{`: مبلغ`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div style={{ direction: 'rtl' }} className='persian-number'>
                                            <span className='mx-1'>{item.TotalPrice}</span>
                                            <span>{item.PriceUnit}</span>
                                        </div>
                                        <div>{`:  مبلغ کل`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div style={{ direction: 'rtl' }} className='persian-number'>
                                            <span className='mx-1'>{item.FinalSummition}</span>
                                            <span>{item.PriceUnit}</span>
                                        </div>
                                        <div>{`:  جمع کل نهایی`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div style={{ direction: 'rtl' }} className='persian-number'>
                                            <span className='mx-1'>{item.SumOfTotalOfCounts}</span>
                                        </div>
                                        <div>{`:  جمع کل تعداد`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div style={{ direction: 'rtl' }} className='persian-number'>
                                            <span className='mx-1'>{item.TotalWeight}</span>
                                            <span>{item.WeightUnit}</span>
                                        </div>
                                        <div>{`:  وزن کل`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div style={{ direction: 'rtl' }} className='persian-number'>
                                            <span className='mx-1'>{item.GoodsDeliveryPrice}</span>
                                            <span>{item.PriceUnit}</span>
                                        </div>
                                        <div>{`:  هزینه ارسال کالا (ریال)`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div style={{ direction: 'rtl' }} className='persian-number'>
                                            <span className='mx-1'>{item.ValueAddedTax}</span>
                                            <span>{item.PriceUnit}</span>
                                        </div>
                                        <div>{`:  مالیات برارزش افزوده (ریال)`}</div>
                                    </div>
                                    <div className='w-full mt-3 flex justify-between items-center px-5'>
                                        <div style={{ direction: 'rtl' }} className='persian-number'>
                                            <span className='mx-1'>{item.RetailDiscount}</span>
                                            <span>{item.PriceUnit}</span>
                                        </div>
                                        <div>{`: تخفیف خرده فروشی`}</div>
                                    </div>
                                    <div className='w-full mt-3 px-5'>
                                        <div className='w-full text-center'>{`روش تحویلی`}</div>
                                        <div className='w-full text-end persian-number'>
                                            <span>{item.DeliveryMethod.Methods1}</span>
                                            <span className='mx-3'>{item.name}</span>
                                            <span className='mx-2'>{item.DeliveryMethod.Methods2}</span>
                                            <span className='mx-2'>{item.date}</span>
                                            <span className=''>{item.DeliveryMethod.Methods3}</span>
                                        </div>
                                        <div className='w-full border-[1px] my-2 border-[#4e4b4ba4]'></div>
                                        <div className='w-full text-center'>
                                            {item.DeliveryMethod.Methods4}
                                        </div>
                                    </div>
                                    <div className='w-full h-[200px] px-3'>
                                        <div className='w-full h-full rounded-xl my-3'>
                                            <div className='w-full h-full border-2 border-[#312e2e5e] rounded-xl my-3'>
                                                <SignatureCanvas
                                                    ref={sigCanvasRefs.current[index]}
                                                    penColor='black'
                                                    canvasProps={{
                                                        width: 500,
                                                        height: 200,
                                                        className: 'sigCanvas'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-center items-center'>
                                        <button onClick={() => clearSignature(index)} className='mt-2 p-2 bg-red-500 text-white rounded'>
                                            صفحه را تمیز کن
                                        </button>
                                    </div>
                                </div>
                                <div className='w-full mb-10 flex px-5 justify-center items-center'>
                                    <button className='w-full rounded-xl py-3 text-[rgba(255,255,255,1)] bg-[#395f8a]'>
                                        دانلود فاکتور
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div onClick={() => BackButton(true)} className='fixed bottom-1 left-1 w-[50px] h-[50px] p-2 rounded-full bg-[#3b729e]'>
                <img src={Return} />
            </div>
        </div>
    )
}

export default ResponsiveRemittance
