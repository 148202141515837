import React, { useState, useRef, useLayoutEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { OrgChart } from "d3-org-chart";
import CustomNodeContent from "./CustomNodeContent";
import CustomExpandButton from "./CustomExpandButton";
import EmployeeDetailsCard from "./EmployeeDetailsCard";

const styles = {
    orgChart: {
        width: '100%',
        height: '100vh',
        backgroundColor: "#ffffff",
    },
};

const OrganizationalChart = (props) => {
    const { width } = props;
    const d3Container = useRef(null);
    const [cardShow, setCardShow] = useState(false);
    const [employeeId, setEmployeeId] = useState("");

    const handleShow = () => setCardShow(true);
    const handleClose = () => setCardShow(false);

    const toggleDetailsCard = (nodeId) => {
        setTimeout(() => {
            handleShow();
            setEmployeeId(nodeId.data.id);
        }, 300); // A short delay to ensure state update happens
    };

    useLayoutEffect(() => {
        const chart = new OrgChart();

        if (props.data && d3Container.current) {
            chart
                .container(d3Container.current)
                .data(props.data)
                .nodeWidth((d) => 300)
                .nodeHeight((d) => 150)
                .compactMarginBetween((d) => 80)
                .onNodeClick((d) => {
                    toggleDetailsCard(d);
                })
                .buttonContent((node, state) => {
                    return ReactDOMServer.renderToStaticMarkup(
                        <CustomExpandButton {...node.node} />
                    );
                })
                .nodeContent((d) => {
                    return ReactDOMServer.renderToStaticMarkup(
                        <CustomNodeContent {...d} />
                    );
                })
                .initialZoom(
                    width < 500 ? 0.5 :
                        width >= 500 && width < 600 ? 0.7 :
                            width >= 600 && width < 700 ? 0.8 :
                                width >= 700 && width < 900 ? 0.9 : 1
                )
                .render();

            // Force state update to trigger re-render after chart is rendered
            setTimeout(() => {
                chart.update(props.data); // Forces update
            }, 500);
        }

        // Cleanup by removing the chart's container content
        return () => {
            if (d3Container.current) {
                d3Container.current.innerHTML = ''; // Clears the chart from the container
            }
        };
    }, [props.data]);

    return (
        <div style={styles.orgChart} ref={d3Container}>
            {cardShow && (
                <EmployeeDetailsCard
                    employees={props.data}
                    employee={props.data.find((employee) => employee.id === employeeId)}
                    handleClose={handleClose}
                    width={width}
                />
            )}
        </div>
    );
};

export default OrganizationalChart;
