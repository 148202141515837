import React, { useState } from 'react';
import LocationIcon from './Partials/LocationIcon';
import { Address } from '../../data/AddressAdding';

const GoodsOrder = (props) => {
    const { width, Loading } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const handleEditClick = (item) => {
        setSelectedAddress(item);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedAddress(null);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('Updated address:', selectedAddress);
        handleCloseModal();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedAddress({ ...selectedAddress, [name]: value });
    };
    const handleAddress = (data) => {
        setSelectedAddress((prev) => ({
            ...prev,
            first_name: '',
            last_name: '',
            telephone: '',
            postal_code: '',
            address: ''
        }))
        setIsModalOpen(true)
    }
    return (
        <div className="w-full persian-number relative min-h-screen lg:w-[calc(100vw-350px)]">
            <LocationIcon
                C1={`${width <= 1280 ? 'absolute right-1 bottom-1' : 'absolute right-5 top-5'} cursor-pointer z-10 rounded-full p-[2px] bg-[#10202e]`}
                C2={`w-[90px] h-[90px] rounded-full border-[1px] border-[#a79e9e]`}
                getAddress={handleAddress}
            />
            {
                width >= 1280 &&
                <div className="w-full xl:text-[14px] 2xl:text-[17px] h-[calc(100vh-120px)] px-4 lg:px-10 absolute bottom-0">
                    <table className="w-full mt-[20px] border-collapse">
                        <thead>
                            <tr>
                                <th className="text-center py-5 border-b-2 border-gray-300">ویرایش</th>
                                <th className="text-center py-5 border-b-2 border-gray-300">ادرس</th>
                                <th className="text-center py-5 border-b-2 border-gray-300">کد پستی</th>
                                <th className="text-center py-5 border-b-2 border-gray-300">شماره تلفن</th>
                                <th className="text-center py-5 border-b-2 border-gray-300">نام خانوادگی</th>
                                <th className="text-center py-5 border-b-2 border-gray-300">نام</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Address.map((item, index) => (
                                <tr key={index} className="border-b border-gray-200">
                                    <td className="text-center py-4">
                                        <button
                                            className="text-blue-500 hover:text-blue-700"
                                            onClick={() => handleEditClick(item)}
                                        >
                                            ویرایش
                                        </button>
                                    </td>
                                    <td className="text-center py-4">{item.address}</td>
                                    <td className="text-center py-4">{item.postal_code}</td>
                                    <td className="text-center py-4">{item.telephone}</td>
                                    <td className="text-center py-4">{item.last_name}</td>
                                    <td className="text-center py-4">{item.first_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {
                width < 1280 && (
                    <div className='w-full lg:h-[100vh] h-[calc(100vh-60px)] px-4 lg:px-10 absolute bottom-0 overflow-y-auto'>
                        <div className='grid gap-4 text-[10px]'>
                            {Address.map((item, index) => (
                                <div key={index} className="border rounded-lg mt-5 p-4 bg-white shadow-md">
                                    <div className='w-full flex justify-between px-5'>
                                        <span>{item.first_name}</span>
                                        <span>{`: نام`}</span>
                                    </div>
                                    <div className='w-full mt-5 flex items-center justify-between px-5'>
                                        <span>{item.last_name}</span>
                                        <span>{`: نام خانوادگی`}</span>
                                    </div>
                                    <div className='w-full mt-5 flex items-center justify-between px-5'>
                                        <span>{item.telephone}</span>
                                        <span>{`: شماره تلفن`}</span>
                                    </div>
                                    <div className='w-full mt-5 flex items-center justify-between px-5'>
                                        <span>{item.postal_code}</span>
                                        <span>{`: کد پستی`}</span>
                                    </div>
                                    <div className='w-full text-end mt-5 flex items-center justify-between px-5'>
                                        <span className='mr-20'>{item.address}</span>
                                        <span className='whitespace-nowrap'>{`: ادرس`}</span>
                                    </div>
                                    <div className='w-full mt-2 py-3 flex items-center justify-center'>
                                        <button onClick={() => handleEditClick(item)} className='w-full py-3 rounded-xl text-base text-center text-white bg-[#16293b]'>
                                            ویرایش
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
            {/* Responsive Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-10 w-full lg:w-[calc(100vw-350px)] px-4">
                    <div className="bg-white mt-[60px] rounded-lg p-6 w-full max-w-md lg:max-w-xl mx-auto" dir="rtl">
                        <h2 className="text-xl lg:text-2xl font-bold mb-4">ویرایش آدرس</h2>
                        <form onSubmit={handleFormSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm lg:text-base pb-2">نام:</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    value={selectedAddress.first_name}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded text-sm lg:text-base"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm lg:text-base pb-2">نام خانوادگی:</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    value={selectedAddress.last_name}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded text-sm lg:text-base"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm lg:text-base pb-2">شماره تلفن:</label>
                                <input
                                    type="text"
                                    name="telephone"
                                    value={selectedAddress.telephone}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded text-sm lg:text-base"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm lg:text-base pb-2">کد پستی:</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    value={selectedAddress.postal_code}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded text-sm lg:text-base"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm lg:text-base pb-2">آدرس:</label>
                                <textarea
                                    name="address"
                                    value={selectedAddress.address}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded text-sm lg:text-base"
                                />
                            </div>
                            <div className="flex justify-end space-x-2 rtl:space-x-reverse">
                                <button
                                    type="button"
                                    className="px-4 py-2 text-white text-sm lg:text-base bg-gray-500 rounded hover:bg-gray-600"
                                    onClick={handleCloseModal}
                                >
                                    انصراف
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 text-sm lg:text-base bg-blue-600 text-white rounded hover:bg-blue-700"
                                >
                                    ذخیره
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GoodsOrder;
